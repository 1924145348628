import { Database } from "emoji-picker-element"
import { Emoji, NativeEmoji } from "emoji-picker-element/shared"

export const shortcodePattern = /:(\w+):/g

/*
    Т.к. shortcode's для эмодзи передаются всегда в латинице вида ":code:", необходимо создать в IndexedDB
    новый инстанс базы эмодзи с en локалями
 */
const initEmojiDatabase = () => {
    return new Database({
        dataSource: `${window.location.origin}${process.env.PUBLIC_URL}/emoji-data-en.json`
    })
}

const emojiDatabase = initEmojiDatabase()

export const getEmojiUnicodeByShortcode = async (db: Database, shortcode: string) => {
    if (!db) {
        return null
    }

    return await db.getEmojiByShortcode(shortcode)
}

export const replaceShortcode = async (shortcode: string) => {
    const cleanShortcode = shortcode.replace(/:/g, "")
    const emoji: Emoji | null = await getEmojiUnicodeByShortcode(emojiDatabase, cleanShortcode)

    if (!emoji) {
        return shortcode
    }

    return (emoji as NativeEmoji).unicode
}

export const replaceAllShortcodes = async (text: string) => {
    const replacements: { match: string; promise: Promise<string> }[] = []

    const replacedText = text.replace(shortcodePattern, match => {
        const replacementPromise = replaceShortcode(match)

        replacements.push({ match, promise: replacementPromise })

        return match
    })

    const promises = replacements.map(r => r.promise)
    const settledReplacements = await Promise.allSettled(promises)

    let result = replacedText
    let index = 0

    result = result.replace(shortcodePattern, () => {
        const { match } = replacements[index]
        const replacement = settledReplacements[index++]

        return replacement.status === "fulfilled" ? replacement.value : match
    })

    return result
}
