import { ApiMessage, ApiScreensharingMessage, MessageCase } from "../../models/Dialogs/message"
import store, { Store } from "../../store/store"
import { IHub } from "../interfaces/IHub"
import { push } from "connected-react-router"
import { pushNotification } from "../../utility/notifications/pushNotifications"
import { actions as dialogsActions } from "../../store/dialogs/slice"
import { actions as knowledgeBaseActions } from "../../store/knowledgeBase/slice"
import { DialogsHubEvents } from "../../models/Dialogs/hub"
import { createTabNotification } from "../../utility/common/TabNotifications"
import { MESSAGES_PER_PAGE, STARTER_MESSAGE } from "../../utility/dialogs/constant"
import { NotificationDto } from "../../models/notificationDto"
import { Dispatch } from "redux"

export const playNotificationSound = () => {
    const audioNode = new Audio("/Assets/sound/notify.mp3")

    const pushNotificationVolumeLevel =
        store.getState().project.settings?.Operator?.Notifications?.PushNotificationVolumeLevel

    audioNode.volume = pushNotificationVolumeLevel || 0.5
    audioNode.currentTime = 0
    audioNode.play().catch(e => console.error("Failed to play sound", e))
}

export const handleEvents = (events: DialogsHubEvents, eventHandler: IHub["registerEvent"]) =>
    Object.entries(events).forEach(([eventName, callback]) => {
        eventHandler(eventName, callback)
    })

export const isStarterMessage = (message: ApiMessage) => {
    return message.Fields.Text === STARTER_MESSAGE
}

export const isSystemMessage = (message: ApiMessage) => {
    return message.Fields.Meta?.system_command_message === "True"
}

export const isCurrentUserSender = (message: ApiMessage, store: Store) => {
    const currentOperator = store.getState().users.currentUser
    return (
        (message.Fields.Sender.Id === currentOperator.data?.Login ||
            message.Fields.Sender.Id === `DEFAULT___${currentOperator.data?.Login}`) &&
        message.Fields.Meta?.initialized_from_timer_service !== "True"
    )
}

export const isScreensharingMessage = (message: unknown): message is ApiScreensharingMessage => {
    return (message as ApiScreensharingMessage).Case === MessageCase.ScreensharingMessage
}

export const applyPushNotificationIfEnabled = ({
    title,
    body,
    projectId,
    dialogId,
    clientId,
    dispatch
}: {
    title: string
    body: string
    dialogId: string
    projectId: string
    clientId: string
    dispatch: Store["dispatch"]
}) => {
    const isPushNotificationEnabled =
        store.getState().project.settings?.Operator?.Notifications?.PushNotificationEnabled

    if (!isPushNotificationEnabled) {
        return null
    }

    let pushNotificationTimeout = store.getState().project.settings?.Operator?.Notifications?.PushNotificationTimeout
    if (typeof pushNotificationTimeout === "number") {
        pushNotificationTimeout = pushNotificationTimeout * 1000
    }

    return pushNotification({
        title,
        body,
        onClick() {
            dispatch(push(`/project/${projectId}/dialogs`))
            dispatch(dialogsActions.setCurrentDialogId(dialogId))
            dispatch(dialogsActions.setCurrentClientOmniUserId(clientId))

            window.focus()

            dispatch(
                dialogsActions.setMessagesPaginationState({
                    OmniUserId: clientId,
                    StartTime: 0,
                    Count: MESSAGES_PER_PAGE,
                    FromTodayDialogs: true,
                    ProjectId: projectId
                })
            )
        },
        timeout: pushNotificationTimeout
    })
}

export const notifyOperator = (
    pushConfig: Parameters<typeof applyPushNotificationIfEnabled>[0],
    tabConfig: Parameters<typeof createTabNotification>[0]
) => {
    playNotificationSound()
    applyPushNotificationIfEnabled(pushConfig)
    createTabNotification(tabConfig)
}

export const getImportProgress = (store: Store) => {
    const stackNews = store.getState().notifications.stack
    const isImportGoing = stackNews.some(news => news.Type === "importFiles")
    const importFilesProgress = store.getState().knowledgeBase.importFilesProgress

    return isImportGoing && importFilesProgress > 0
}

export const handleArticleUploadFinish = (
    store: Store,
    message: NotificationDto,
    dispatch: Dispatch,
    callback?: () => void
) => {
    const importedFiles = store.getState().knowledgeBase.importFiles
    const lastImportFile = importedFiles[importedFiles.length - 1]

    if (message.Context && lastImportFile.name === message.Context["filename"]) {
        dispatch(knowledgeBaseActions.setImportFiles([]))
        callback && callback()
    }
}
