import cn from "classnames"
import { memo, useEffect, useRef } from "react"
import { fetchNewsline } from "../../api/controllers/knowledgeBase/helpers"
import { newslineHub } from "../../api/instances/newslineHub"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { selectNews } from "../../store/knowledgeBase/selectors"
import { selectCurrentProjectId } from "../../store/projects/selectors"
import { testId } from "../../utility/tests/testId"
import PopoverTrigger from "../PopoverTrigger/PopoverTrigger"
import { BounceIndicator } from "./components/BounceIndicator/BounceIndicator"
import { NewslineItem } from "./components/NewslineItem/NewslineItem"
import { NewsList } from "./components/NewsList/NewsList"
import { useNewslineRotation } from "./hooks/useNewslineRotation"
import styles from "./Newsline.module.scss"

interface NewslineProps {
    className?: string
}

const popperConfig = { modifiers: [{ name: "offset", options: { offset: [0, 0] } }] }

export const Newsline = memo(({ className }: NewslineProps) => {
    const news = useAppSelector(selectNews)
    const dispatch = useAppDispatch()
    const newslineRef = useRef<HTMLDivElement>(null)
    const newsRef = useRef<HTMLUListElement>(null)
    const projectId = useAppSelector(selectCurrentProjectId)

    useEffect(() => {
        if (projectId) {
            dispatch(fetchNewsline(projectId))
        }
    }, [projectId, dispatch])

    useEffect(() => {
        newslineHub.subscribe()
        newslineHub.registerServerEvents()

        return () => {
            newslineHub.unregisterServerEvents()
            newslineHub.unsubscribe()
        }
    }, [])

    useNewslineRotation(newsRef, news)

    if (!news || !news.length) return null

    return (
        <PopoverTrigger
            id="newsline-list"
            className={styles.popover}
            content={<NewsList list={news} />}
            trigger="click"
            placement="bottom"
            popperConfig={popperConfig}
            container={() => newslineRef.current}
            rootClose
        >
            <div className={cn(styles.newsline, className)} ref={newslineRef} data-testid={testId.newsline}>
                <BounceIndicator />
                <div className={styles.view}>
                    <ul className={styles.news} ref={newsRef}>
                        {news.map(newsItem => (
                            <NewslineItem key={newsItem.ArticleCode} item={newsItem} />
                        ))}
                        {/* Дублирование элемента нужно для замыкания круга анимации */}
                        <NewslineItem item={news[0]} />
                    </ul>
                </div>
            </div>
        </PopoverTrigger>
    )
})
