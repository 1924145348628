type TestId = {
    [key: string]: string
}

export const testId: TestId = {
    activeTasksCount: "active-tasks-count",
    addAllRights: "add-all-rights",
    addAgent: "add-agent",
    addChannel: "add-channel",
    addClassifier: "add-classifier",
    addNewUserChannel: "add-new-user-channel",
    additionalMenu: "additional-menu",
    addQueue: "add-queue",
    addRole: "add-role",
    addSlot: "add-slot",
    addPermission: "add-permission",
    addSticker: "add-sticker",
    addSurvey: "add-survey",
    addType: "add-type",
    allUsersTableSearch: "all-users-table-search",
    agents: "agents",
    alertDialogCancel: "alert-dialog-cancel",
    alertDialogSubmit: "alert-dialog-submit",
    article: "article",
    articleStatus: "article-status",
    articleSendToBot: "article-send-to-bot",
    articleComments: "article-comments",
    articleToolbar: "article-toolbar",
    articleImportFile: "article-import-file",
    articlePublishInfo: "article-publish-info",
    btnClose: "btn-close",
    category: "category",
    channelScenario: "channel-scenario",
    channels: "channels",
    channelSelectInput: "channel-select-input",
    chatBot: "chat-bot",
    clearAll: "clear-all",
    colorInputContainer: "color-input-container",
    copyBtn: "copy-btn",
    createFromCopy: "create-from-copy",
    createMassMailing: "create-mass-mailing",
    createProject: "create-project",
    currentStatus: "current-status",
    datePickerInput: "date-picker-input",
    datePickerIcon: "date-picker-icon",
    datePicker: "date-picker",
    datePickerPreset: "date-picker-preset",
    deleteAgent: "delete-agent",
    deleteChannel: "delete-channel",
    deleteMassMailing: "delete-mass-mailing",
    dialogs: "dialogs",
    dialogHeader: "dialog-header",
    dialogBody: "dialog-body",
    dialogFooter: "dialog-footer",
    dialogAttachments: "dialog-attachments",
    dialogAutoHolding: "dialog-auto-holding",
    dialogAutoHoldingCancelButton: "dialog-auto-holding-cancel-button",
    dialogChat: "dialog-chat",
    dialogPathSelectedChannel: "dialog-path-selected-channel",
    dialogView: "dialog-view",
    draggableButton: "draggable-button",
    dialogTimer: "dialog-badge-timer",
    downloadMassMailingFile: "download-mass-mailing-file",
    downloadReport: "download-report",
    draggingArea: "dragging-area",
    dropPlace: "drop-place",
    dropPlaceContent: "drop-place-content",
    editQueue: "edit-queue",
    email: "email",
    collapseSidebar: "collapse-sidebar",
    favoriteArticle: "favorite-article",
    fileUploaderInput: "file-uploader-input",
    forgotPassword: "forgot-password",
    forgotPasswordSubmit: "forgot-password-submit",
    general: "general",
    history: "history",
    inviteUsers: "invite-users",
    importFilesKB: "import-files-kb",
    knowledgeBase: "knowledge-base",
    knowledgeBaseOld: "knowledge-base-old",
    logOut: "log-out",
    logging: "logging",
    login: "login",
    leftSidebar: "left-sidebar",
    makeNewMassMailingReport: "make-new-mass-mailing-report",
    massMailingActiveList: "mass-mailing-active-list",
    massMailingFinishedList: "mass-mailing-finished-list",
    massMailingFormInput: "mass-mailing-form-input",
    massMailingListItem: "mass-mailing-list-item",
    messageInput: "message-input",
    messageBasic: "message-basic",
    messageUserTyping: "message-user-typing",
    editedMessage: "edited-message",
    enableAll: "enable-all",
    deleteAllRights: "delete-all-rights",
    turnOffAll: "turn-off-all",
    messageAttachments: "message-attachments",
    messageInputSubmitButton: "message-input_submit-button",
    messageInputSubmitAndHoldButton: "message-input_submit-and-hold-button",
    newsItem: "news-item",
    newsline: "newsline",
    notificationList: "notification-list",
    notificationPayload: "notification-payload",
    operators: "operators",
    openMassMailingForm: "open-mass-mailing-form",
    openMassMailingReports: "open-mass-mailing-reports",
    password: "password",
    pauseMassMailing: "pause-mass-mailing",
    permissions: "permissions",
    portalSystem: "portal-system",
    priorityValue: "priority-value",
    projectDialogSettingsForm: "project-dialog-settings-form",
    projectGeneralSettingsForm: "project-general-settings-form",
    projectOperatorSettingsForm: "project-operator-settings-form",
    projectSelector: "project-selector",
    projectSettings: "project-settings",
    projectStatisticsSettingsForm: "project-statistics-settings-form",
    projectSystemSettingsForm: "project-system-settings-form",
    queueName: "queue-name",
    queuedTasksCount: "queued-tasks-count",
    queuedIndividualTasksCount: "queued-individual-tasks-count",
    queues: "queues",
    roles: "roles",
    reloadMassMailingItems: "reload-mass-mailing-items",
    saveAgent: "save-agent",
    saveChannel: "save-channel",
    saveProjectSettings: "save-project-settings",
    saveQueue: "save-queue",
    saveSurveyField: "save-survey-field",
    saveNewsline: "save-newsline-settings-btn",
    scrollUpButton: "scroll-up-button",
    scrollDownButton: "scroll-down-button",
    searchInput: "search-input",
    searchProject: "search-project",
    selectAll: "select-all",
    selectFile: "select-file",
    settingsButton: "settings-button",
    startMassMailing: "start-mass-mailing",
    stats: "stats",
    submitEnter: "submit-enter",
    sendMessageAction: "send-message-action",
    tagInput: "tag-input",
    taskSizeValue: "task-size-value",
    testChat: "test-chat",
    toggleChannelActivity: "toggle-channel-activity",
    toNextPage: "to-next-page",
    toPreviousPage: "to-previous-page",
    user: "user",
    userCard: "user-card",
    userManagement: "user-management",
    userProfileForm: "user-profile-form",
    users: "users",
    wizardStageBackBtn: "wizard-stage-back-btn",
    wizardStageNextBtn: "wizard-stage-next-btn",
    attachmentItem: "attachment-item",
    attachmentLink: "attachment-link",
    attachmentPreview: "attachment-preview",
    galleryPreviewer: "gallery-previewer",
    galleryBox: "gallery-box",
    queueAutoGreetingToggle: "queue-auto-greeting-toggle",
    queueAutoGreetingHoldToggle: "queue-auto-greeting-toggle-hold",
    queueAutoGreetingTemplate: "queue-auto-greeting-template",
    articleActions: "article-actions",
    fastRepliesItem: "fast-reply-item",
    invalidSumOfLimits: "sum-limits-validation-error",
    dialogsBandwidthByType: "dialogs-bandwidth-by-type",
    articleHeader: "article-header",
    operatorStatusTimer: "operator-status-timer",
    operatorTasks: "operator-tasks",
    operatorsTableSearch: "operators-table-search",
    queueTasksSearch: "queue-tasks-search",
    queueTasksInQueueButton: "queue-tasks-in-queue-button",
    queuePendingTasksInQueueButton: "queue-tasks-in-queue-button",
    sidebarActionButton: "sidebar-action-button",
    queueIdOnOperatorRefused: "queue-id-on-operator-refused",
    dialogTransferModalSubmit: "dialog-transfer-modal-submit",
    dialogTransferModal: "dialog-transfer-modal",
    pinClose: "pin-close",
    queueAllowCatalogsSettings: "queue-allow-catalogs-settings",
    customMenu: "custom-menu",
    messageReroute: "message-reroute"
}
