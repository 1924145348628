import { AdvancedSkeletonProps } from "./AdvancedSkeleton.props"
import Skeleton from "./../Skeleton/Skeleton"

export const AdvancedSkeleton = (props: AdvancedSkeletonProps) => {
    const { width, height } = props
    return (
        <div style={{ width, height }}>
            <Skeleton />
        </div>
    )
}
