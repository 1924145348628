import { GetGlobalUserSurveyResponse } from "../../models/globalUserSurvey"
import {
    CreateSurveyRequest,
    CreateSurveyResponse,
    DeleteSurveyResponse,
    GetSurveysRequest,
    GetSurveysResponse,
    SurveyDto,
    UpdateSurveyRequest,
    UpdateSurveyResponse
} from "../../models/survey"
import { apiInstance } from "../instances/apiInstance"
import { queryApi } from "../api"

export const surveysController = {
    getSurveys: (projectId: string, request: GetSurveysRequest): Promise<GetSurveysResponse> =>
        apiInstance.post(`${projectId}/survey/page`, request).then(response => response.data),
    getSurvey: (projectId: string, surveyId: string): Promise<SurveyDto> =>
        apiInstance.get(`${projectId}/survey/${surveyId}`).then(response => response.data),
    create: (projectId: string, request: CreateSurveyRequest): Promise<CreateSurveyResponse> =>
        apiInstance.post(`${projectId}/survey`, request).then(response => response.data),
    update: (projectId: string, surveyId: string, request: UpdateSurveyRequest): Promise<UpdateSurveyResponse> =>
        apiInstance.put(`${projectId}/survey/${surveyId}`, request).then(response => response.data),
    delete: (projectId: string, surveyId: string): Promise<DeleteSurveyResponse> =>
        apiInstance.delete(`${projectId}/survey/${surveyId}`).then(response => response.data)
}

export const surveysApiController = queryApi.injectEndpoints({
    endpoints: build => {
        return {
            getGlobalUserSurvey: build.query<GetGlobalUserSurveyResponse, void>({
                query: () => ({
                    url: "/survey/default-project-survey",
                    method: "GET"
                })
            })
        }
    }
})

export const { useLazyGetGlobalUserSurveyQuery } = surveysApiController
