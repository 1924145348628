import { memo } from "react"
import styles from "./RerouteInfoContainer.module.scss"
import { GetDialogLastReroute } from "../../models/Dialogs/dialog"
import { MessageRerouteDialog } from "../Dialogs/DialogMessage/components/MessageRerouteDialog/MessageRerouteDialog"

export type RerouteInfoProps = {
    info: GetDialogLastReroute
    className?: string
}

export const RerouteInfoContainer = memo((props: RerouteInfoProps) => {
    const { info } = props
    const preferred = info.Preferred
    const confirmed = info.Confirmed

    return (
        <div className={styles.message}>
            {preferred && <MessageRerouteDialog message={preferred} isColumnAlign className={styles.firstMessage} />}
            {confirmed && (
                <MessageRerouteDialog
                    message={confirmed}
                    lastOperatorDialogRerouteMessage={preferred}
                    isColumnAlign
                    className={styles.secondMessage}
                />
            )}
        </div>
    )
})
