import { useEffect, useRef } from "react"
import { useIsTabActive } from "../../../hooks/useIsTabActive"
import { NewslineItem } from "../../../models/knowledgeBase/newsline"

const ROTATION_DURATION = 1500
const TRANSITION_DURATION = 500

export const useNewslineRotation = (newsRef: React.RefObject<HTMLUListElement>, news?: NewslineItem[]) => {
    const index = useRef<number>(0)
    const isTabActive = useIsTabActive()

    // Сброс ленты в начальное состояние без прокрутки
    const reset = () => {
        if (newsRef.current) {
            index.current = 0
            newsRef.current.style.transition = "none"
            newsRef.current.style.transform = "translateY(0px)"
        }

        setTimeout(() => {
            if (newsRef.current) {
                newsRef.current.style.transition = "transform 0.5s ease-in-out"
            }
        }, 50)
    }

    useEffect(() => {
        if (!isTabActive || !news || news.length <= 1) return

        const interval = setInterval(() => {
            index.current++

            if (newsRef.current) {
                newsRef.current.style.transform = `translateY(${index.current * -50}px)`
            }

            if (index.current === news.length) {
                setTimeout(reset, TRANSITION_DURATION)
            }
        }, ROTATION_DURATION)
        reset()

        return () => clearInterval(interval)
    }, [isTabActive, news?.length])
}
