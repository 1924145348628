import { Dispatch } from "../../../utility/common/storeHelper"
import { LinkedToOperatorClient } from "../../../models/Dialogs/linksToOperator"
import { dialogsApi } from "../../../api/controllers/dialogs"
import { UserAccessActions } from "../../../models/knowledgeBase/userAccess"

export const updateLinkedClients = (
    dispatch: Dispatch,
    action: string,
    client: LinkedToOperatorClient,
    onlyLinkedPersonal: boolean
) => {
    switch (action) {
        case UserAccessActions.Added:
            dispatch(
                dialogsApi.util.updateQueryData("getLinkedToOperatorClients", onlyLinkedPersonal, oldState => {
                    const index = oldState.findIndex(linkedClient => linkedClient.OmniUserId === client.OmniUserId)

                    if (index === -1) oldState.push(client)
                })
            )
            break
        case UserAccessActions.Removed:
            dispatch(
                dialogsApi.util.updateQueryData("getLinkedToOperatorClients", onlyLinkedPersonal, oldState => {
                    const indexToRemove = oldState.findIndex(
                        linkedClient => linkedClient.OmniUserId === client.OmniUserId
                    )

                    oldState.splice(indexToRemove, 1)
                })
            )
            break
    }
}
