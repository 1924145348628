import { UploadedFile } from "../utility/common/files"
import { MessageType } from "./Dialogs/message"
import { FilledSlot } from "./slot"

// NB: SendingJob === MassMailing
// NB: SendingJobItem === MassMailingItem

export enum SendingJobRunMode {
    Stop,
    Run,
    Schedule
}

export enum SendingJobRunStatus {
    Inactive,
    Active,
    Completed
}

export enum SendingJobDeliveryPollStatus {
    EarlyInactive,
    Active,
    Completed
}

export enum SendingJobMethod {
    SendMessage = 1,
    SendIntent = 2
}

export enum SendingJobItemStatus {
    NotProcessed,
    SentAndNeedCascade,
    Completed
}

export interface AuditInfo {
    CreateByUserId: string
    UpdateByUserId: string
    DeleteByUserId?: string
    CreateTimestamp: number
    UpdateTimestamp: number
    DeleteTimestamp?: number
}

export interface SendingJob {
    Id: string
    ProjectId: string
    CopiedFromId?: string
    Deleted: boolean
    Audit: AuditInfo
    Data: SendingJobData
    State: SendingJobState
    Statistic: SendingJobStatistic
}

export interface SendingJobStatistic {
    CountItems: number
    ErrorItems: number
    SentItems: number
    DeliveredItems: number
}

export interface SendingJobData {
    ExtId?: string
    Title: string
    FinishNewDialog: boolean
    Method: SendingJobMethod
    RunMode: SendingJobRunMode
    FilledSlots?: FilledSlot[]
    ScheduleTime?: number
    SendingCascade?: SendingCascade
    MessageMarkdown?: string
    IntentSymbolCode?: string
    RedirectAgentId?: string
    CsvFileLink?: string
}

export interface SendingJobState {
    RunStatus: SendingJobRunStatus
    DeliveryPollStatus: SendingJobDeliveryPollStatus
    LastSentTimestamp?: number
    LastDeliveryPollTimestamp?: number
}

export interface SendingJobItem {
    Id: string
    ParentId: string
    Deleted: boolean
    Data: SendingJobItemData
    State: SendingJobItemState
    Internals: SendingJobItemStatusInternals
}

export interface SendingJobItemData {
    ExtId?: string
    CascadeOmniUserParams?: SendMessageParams
    SendingCascade?: SendingCascade
    MessageMarkdown?: string
    IntentSymbolCode?: string
    RedirectAgentId?: string
}

export interface SendingJobItemState {
    SendingStatus: SendingJobItemStatus
    DeliveryPollCompleted: boolean
    SendingResults?: SendingVariantResult[]
    Errors?: string[]
    LastConfirmation?: {
        Type: SendingConfirmation
        Timestamp: number
    }
}

export interface SendingVariantResult {
    SendMessageParams: SendMessageParams
    SendMessageResponse: SendMessageResponse
    SendMessageTimestamp: number
    Confirmations: SendingConfirmation[]
    ConfirmationsTimestamps: number[]
}

export interface SendingJobItemStatusInternals {
    CascadeNextVariant?: number
    CascadeNextVariantChannelUserIds?: string[]
}

export interface SendingCascade {
    TimeoutBeforeNextVariant: number
    Variants: SendingVariant[]
}

export interface SendingVariant {
    SendMessageParams?: SendMessageParams
    ChannelIdForOmniUsers?: string
    TimeoutBeforeNextVariant?: number
    WaitConfirmationCode?: MessageType
}

export type MassMailingResponse<T> =
    | {
          data: T
      }
    | {
          errors: ErrorInfo[]
      }

interface ErrorInfo {
    message: string
    extensions?: ErrorInfoExtension
}
interface ErrorInfoExtension {
    ErrorName?: string
}

type SendingConfirmation = MessageType

interface SendMessageParams {
    ProjectId: string
    OmniUserId?: string
    DestinationChannel: ChannelInfo
    FinishNewDialog: boolean
    Sender?: Record<string, string>
    FilledSlots?: Record<string, string>
}

interface SendMessageResponse {
    Success: boolean
    Result?: SendMessageResult
    Errors?: string[]
}

interface SendMessageResult {
    MessageId: string
    DialogId?: string
    ChannelId: string
    OmniUserId: string
}

interface ChannelInfo {
    ChannelId: string
    ChannelUserId: string
}

export enum MassMailingFormat {
    omni = "omni",
    channel = "channel"
}

export enum MassMailingMethod {
    message = "message",
    article = "article"
}

export enum MassMailingFormFields {
    title = "title",
    format = "format",
    channel = "channel",
    attachment = "attachment",
    isScheduled = "isScheduled",
    scheduleTime = "scheduleTime",
    method = "method",
    message = "message",
    article = "article"
}

export interface MassMailingFormValues {
    [MassMailingFormFields.title]: string
    [MassMailingFormFields.format]: MassMailingFormat
    [MassMailingFormFields.channel]: string
    [MassMailingFormFields.attachment]?: UploadedFile | null
    [MassMailingFormFields.isScheduled]: boolean
    [MassMailingFormFields.scheduleTime]: number
    [MassMailingFormFields.method]: MassMailingMethod
    [MassMailingFormFields.message]?: string
    [MassMailingFormFields.article]?: {
        Code: string
        Title: string
    }
}

export enum MassMailingUserStatuses {
    sheduled = "sheduled",
    active = "active",
    paused = "paused",
    finished = "finished",
    deleted = "deleted"
}

export enum MassMailingItemUserStatuses {
    inactive = "inactive",
    planned = "planned",
    cascade = "cascade",
    completed = "completed",
    error = "error",
    deleted = "deleted"
}

export interface MassMailingEventData {
    EventId: string
    ProjectId: string
    MassSendingJob: SendingJob
}

export enum MassMailingGroups {
    active = "active",
    finished = "finished"
}

export interface MassMailingGroup {
    name: MassMailingGroups
    items: SendingJob[]
}

// ==================== Requests and Responses ====================

interface OptionalIds {
    Id?: string
    ExtId?: string
}

export interface CreateSendingJobRequest {
    ProjectId: string
    CopiedFromId?: string
    Data?: SendingJobData
}

export interface GetSendingJobRequest extends OptionalIds {
    ProjectId: string
}

export interface UpdateSendingJobRequest extends OptionalIds {
    ProjectId: string
    Data: SendingJobData
}

export interface DeleteSendingJobRequest extends OptionalIds {
    ProjectId: string
}

export interface SearchSendingJobRequest {
    ProjectId: string
    DateCreateFrom: number
    DateCreateTo: number
    ScheduleTimeFilter?: number
    UserId?: string
    CopiedFromId?: string
    TitleFilter?: string
    MessageFilter?: string
    IntentFilter?: string
    RunMode?: SendingJobRunMode
    RunStatus?: SendingJobRunStatus
    DeliveryPollStatus?: SendingJobDeliveryPollStatus
}

export type SearchSendingJobResponse = {
    SendingJobs: SendingJob[]
    Truncated: boolean
}

export interface AddSendingJobItemsRequest extends OptionalIds {
    ProjectId: string
    Items?: string[]
    CsvLink?: string
}

export interface AddSendingJobItemsResponse {
    InputCount: number
    CreatedCount: number
    ValidateWarnings?: ErrorInfo[]
}

interface GetSendingJobItemsBase extends OptionalIds {
    ProjectId: string
    SendingStatusFilter?: SendingJobItemStatus
    DeliveryPollCompletedFilter?: boolean
}

export interface GetSendingJobItemsRequest extends GetSendingJobItemsBase {
    Limit: number
    Cursor?: string
}

export interface GetSendingJobItemsResponse {
    Items: SendingJobItem[]
    Truncated: boolean
    Cursor?: string
}

export interface GetSendingJobItemsByPageRequest extends GetSendingJobItemsBase {
    PageFrom: number
    PageSize: number
}

export interface GetSendingJobItemsByPageResponse {
    Items: SendingJobItem[]
}

export interface DeleteSendingJobItemsRequest extends OptionalIds {
    ProjectId: string
    Items: string[]
}

export type DeleteSendingJobItemsResponse = string[]

export interface DeleteByParentSendingJobItemsRequest {
    ProjectId: string
    ParentId: string
}

export type DeleteByParentSendingJobItemsResponse = boolean

export interface MakeMassMailingReportResponse {
    Success: true
    ReadyReportId: string
}

export interface MakeMassMailingReportRequest {
    CustomerId: string
    SendingJobId: string
}
