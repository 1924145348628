import { Agent, AgentType, JSAgentDeclaration, JSAgentSettingsTypes, JSAgentValues } from "../../models/agent"
import { Time } from "../common/time"

export const DEFAULT_JSAGENT_TIMEOUT = 10
export const SPACES_COUNT = 4

export const JSAGENT_ADDITIONAL_SETTINGS_KEY = "agent-settings"
export const JSAGENT_TIMEOUT_KEY = "agent-timeout"
export const JSAGENT_CODE_KEY = "agent-code"
export const JSAGENT_AIASSIST_AGENT_ID_KEY = "aiassist-agent-id"
export const JSAGENT_IS_MODULE_KEY = "agent-is-module"
export const JSAGENT_MODULES_KEY = "agent-modules"
export const JSAGENT_API_VERSION_KEY = "agent-api-version"

export const defaultJSAgentValues = {
    Id: "",
    Timeout: DEFAULT_JSAGENT_TIMEOUT,
    Code: "",
    AIAgent: AgentType.AIAssist2,
    Settings: "{}",
    FailOverAgent: "",
    Declaration: [],
    IsModule: false,
    Modules: []
}

const extractJSModules = (modulesSetting: string) => {
    if (!modulesSetting) {
        return []
    }

    return modulesSetting
        .split(",")
        .map(module => module.trim())
        .filter(module => module !== "")
}

export const getValuesFromJSAgent = (agent: Agent): JSAgentValues => {
    const settings = JSON.parse(agent.Params[JSAGENT_ADDITIONAL_SETTINGS_KEY])

    return {
        Id: agent.Id,
        Timeout: Time.msToSeconds(agent.Params[JSAGENT_TIMEOUT_KEY]),
        Code: agent.Params[JSAGENT_CODE_KEY],
        AIAgent: agent.Params[JSAGENT_AIASSIST_AGENT_ID_KEY],
        FailOverAgent: agent.FailOverAgent,
        Settings: agent.Params[JSAGENT_ADDITIONAL_SETTINGS_KEY],
        Declaration:
            settings.__declaration__?.map((declaration: JSAgentDeclaration) => ({
                ...declaration,
                value:
                    declaration.type === JSAgentSettingsTypes.Object
                        ? JSON.stringify(settings[declaration.id], undefined, SPACES_COUNT)
                        : settings[declaration.id]
            })) ?? [],
        IsModule: agent.Params[JSAGENT_IS_MODULE_KEY] === "true",
        Modules: extractJSModules(agent.Params[JSAGENT_MODULES_KEY])
    }
}
