import { SimpleUser, User } from "../../models/user"
import unescape from "lodash-es/unescape"
import { GlobalUser } from "../../models/globalUser"

export function processUser<T extends User | SimpleUser | GlobalUser>(userDto: T): T {
    return {
        ...userDto,
        FirstName: unescape(userDto.FirstName),
        LastName: unescape(userDto.LastName)
    }
}
