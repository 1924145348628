import {
    Channel,
    ChannelDto,
    ChannelTypeString,
    CommonChannel,
    WebChatChannel,
    WhatsappChannelType
} from "../../models/channel"
import {
    ChannelDeclaration,
    ChannelDeclarations,
    ChannelDeclarationsDto,
    ParameterDeclaration,
    ParameterDeclarationDto,
    ParameterType,
    ParamValues,
    WebChatChannelDeclaration
} from "../../models/parameterDeclaration"
import { getDefaultParams, parseField, parseFieldWithArrayCheck } from "../common/parametersTranslator"
import { Time } from "../common/time"

const MAIN_CONFIG_FIELD_REGEX = /^__webchat_config__(?!client_config_)(.*)/
const CLIENT_CONFIG_FIELD_REGEX = /^__webchat_config__client_config_(.*)/
const WEBCHAT_CONFIG_REGEX = /^__webchat_config__(client_config_)?/

const MAIN_CONFIG_FIELD_PREFIX = "__webchat_config__"
const CLIENT_CONFIG_FIELD_PREFIX = "__webchat_config__client_config_"

const defaultRefreshInterval = Time.minutesToMs(5)

export const isWebChat = (channel: Channel): channel is WebChatChannel => channel.Type === ChannelTypeString.WebChat

export const isWebChatChannelDeclaration = (
    declarations?: ChannelDeclaration
): declarations is WebChatChannelDeclaration =>
    !!declarations && "MainConfig" in declarations && "ClientConfig" in declarations

const extractCommonParams = (params: ParamValues): ParamValues =>
    Object.keys(params).reduce((obj: ParamValues, key) => {
        const field = WEBCHAT_CONFIG_REGEX.test(key)
        !field && (obj[key] = params[key])

        return obj
    }, {})

const extractConfig = (params: ParamValues, regex: RegExp): ParamValues =>
    Object.keys(params).reduce((obj: ParamValues, key) => {
        const field = key.match(regex)
        field && (obj[field[1]] = params[key])

        return obj
    }, {})

const extractMainConfig = (params: ParamValues, mainConfigDefaults: ParamValues): ParamValues => ({
    ...mainConfigDefaults,
    ...extractConfig(params, MAIN_CONFIG_FIELD_REGEX)
})

const extractClientConfig = (params: ParamValues, clientConfigDefaults: ParamValues): ParamValues => ({
    ...clientConfigDefaults,
    ...extractConfig(params, CLIENT_CONFIG_FIELD_REGEX)
})

const mapChannelDtoToCommonChannel = (channel: ChannelDto): CommonChannel => {
    channel.Params = extractCommonParams(channel.Params)
    return channel
}

const mapChannelDtoToWebChatChannel = (data: ChannelDto, declarations: ChannelDeclarations): Channel => {
    const params = { ...data.Params }
    const channel = mapChannelDtoToCommonChannel(data)

    if (isWebChatChannelDeclaration(declarations.webchat)) {
        return {
            ...channel,
            MainConfig: extractMainConfig(params, getDefaultParams(declarations.webchat.MainConfig)),
            ClientConfig: extractClientConfig(params, getDefaultParams(declarations.webchat.ClientConfig))
        }
    } else {
        return channel
    }
}

export const mapChannelDtoToChannel = (channel: ChannelDto, declarations: ChannelDeclarations): Channel =>
    isWebChat(channel) ? mapChannelDtoToWebChatChannel(channel, declarations) : channel

const mapCommonChannelToChannelDto = (channel: Channel): ChannelDto => {
    const params = { ...channel.Params }

    Object.keys(params).forEach(key => {
        if (typeof params[key] !== "string") {
            params[key] = JSON.stringify(params[key])
        }
    })

    return {
        Id: channel.Id,
        Type: channel.Type,
        Title: channel.Title,
        ProjectId: channel.ProjectId,
        BotId: channel.BotId,
        Token: channel.Token,
        WebHook: channel.WebHook,
        PreprocessorWebHook: channel.PreprocessorWebHook,
        BandwidthSettingsValues: {
            IncludeChannelInGroup: channel.BandwidthSettingsValues?.IncludeChannelInGroup ?? false,
            Group: channel.BandwidthSettingsValues?.IncludeChannelInGroup ? channel.BandwidthSettingsValues?.Group : ""
        },
        Params: params,
        ServerAddress: channel.ServerAddress
    }
}

const mapWebChatChannelToChannelDto = (channel: WebChatChannel): ChannelDto => {
    const data = mapCommonChannelToChannelDto(channel)

    Object.keys(channel.MainConfig).forEach(key => {
        const translatedKey = `${MAIN_CONFIG_FIELD_PREFIX}${key}`
        typeof channel.MainConfig[key] === "string"
            ? (data.Params[translatedKey] = channel.MainConfig[key])
            : (data.Params[translatedKey] = JSON.stringify(channel.MainConfig[key]))
    })
    Object.keys(channel.ClientConfig).forEach(key => {
        const translatedKey = `${CLIENT_CONFIG_FIELD_PREFIX}${key}`
        typeof channel.ClientConfig[key] === "string"
            ? (data.Params[translatedKey] = channel.ClientConfig[key])
            : (data.Params[translatedKey] = JSON.stringify(channel.ClientConfig[key]))
    })
    return data
}

export const mapChannelToChannelDto = (channel: Channel): ChannelDto =>
    isWebChat(channel) ? mapWebChatChannelToChannelDto(channel) : mapCommonChannelToChannelDto(channel)

const mapToParameterDeclaration = (channelDeclaration: ParameterDeclarationDto): ParameterDeclaration => ({
    ...channelDeclaration,
    name: channelDeclaration.name.replace(WEBCHAT_CONFIG_REGEX, "")
})

export const mapToChannelDeclarations = (declarations: ChannelDeclarationsDto): ChannelDeclarations => {
    const parameterDeclarations: ChannelDeclarations = {}

    Object.keys(declarations).forEach(key => {
        if (key === ChannelTypeString.WebChat) {
            parameterDeclarations[key] = {
                Params: declarations[key]
                    .filter(field => !WEBCHAT_CONFIG_REGEX.test(field.name))
                    .map(mapToParameterDeclaration),
                MainConfig: declarations[key]
                    .filter(field => MAIN_CONFIG_FIELD_REGEX.test(field.name))
                    .map(mapToParameterDeclaration),
                ClientConfig: declarations[key]
                    .filter(field => CLIENT_CONFIG_FIELD_REGEX.test(field.name))
                    .map(mapToParameterDeclaration)
            }
        } else {
            parameterDeclarations[key] = {
                Params: declarations[key]
            }
        }
    })

    return parameterDeclarations
}

export const extractParams = (channelType: string, params: ParamValues): ParamValues => {
    switch (channelType) {
        case ChannelTypeString.Telegram:
            params.always_hide_keyboard = parseField(params.always_hide_keyboard, true)
            params.resize_keyboard = parseField(params.resize_keyboard, false)
            params.allow_add_to_client = parseField(params.allow_add_to_client, false)
            break

        case ChannelTypeString.Viber:
            params.file_max_size_mb = parseField(params.file_max_size_mb, 0)
            params.allow_add_to_client = parseField(params.allow_add_to_client, false)
            break

        case ChannelTypeString.Email:
            params.each_message_as_unique_dialog = parseField(params.each_message_as_unique_dialog, false)
            params.smtp_enable_auth = parseField(params.smtp_enable_auth, true)
            params.smtp_enable_ssl_certificate_check = parseField(params.smtp_enable_ssl_certificate_check, true)
            params.imap_enable_ssl_certificate_check = parseField(params.imap_enable_ssl_certificate_check, true)
            params.merge_to_emails_with_cc_in_email_slot = parseField(
                params.merge_to_emails_with_cc_in_email_slot,
                false
            )
            params.refresh_interval = parseField(params.refresh_interval, defaultRefreshInterval)
            params.read_messages_count = parseField(params.read_messages_count, 0)
            params.file_max_size_mb = parseField(params.file_max_size_mb, 0)
            params.enable_score = !parseField(params["disable-score"], false)
            params.is_outgoing_enabled = parseField(params.is_outgoing_enabled, false)
            params.allow_add_to_client = parseField(params.allow_add_to_client, true)
            break

        case WhatsappChannelType.InfoBIP:
        case WhatsappChannelType.StreamTel:
        case WhatsappChannelType.MFMS:
            params.enable_text_actions = parseField(params.enable_text_actions, false)
            params.ask_fcr = parseField(params.ask_fcr, false)
            params.ask_csi = parseField(params.ask_csi, false)
            params.payload_instead_of_text = parseField(params.payload_instead_of_text, false)
            params.is_outgoing_enabled = parseField(params.is_outgoing_enabled, false)
            params.allow_add_to_client = parseField(params.allow_add_to_client, true)
            break

        case ChannelTypeString.WebChat:
            params.block = parseField(params.block, false)
            params.cobrowsing_permission = parseField(params.cobrowsing_permission, false)
            params.is_outgoing_enabled = parseField(params.is_outgoing_enabled, false)
            params.do_not_send_finish_message_when_dialog_is_not_effective = parseField(
                params.do_not_send_finish_message_when_dialog_is_not_effective,
                false
            )
            params.enable_text_actions = parseField(params.enable_text_actions, false)
            params.replace_operator_name = parseField(params.replace_operator_name, false)
            params.send_only_first_name = parseField(params.send_only_first_name, false)
            params.outgoing_message_max_length = parseField(params.outgoing_message_max_length, 0)
            params.allow_add_to_client = parseField(params.allow_add_to_client, false)
            break

        case ChannelTypeString.Instagram:
            params.dialog_score = !parseField(params["disable-score"], false)
            params.allow_add_to_client = parseField(params.allow_add_to_client, false)
            break

        case ChannelTypeString.Facebook:
            params.allow_add_to_client = parseField(params.allow_add_to_client, false)
            break
    }

    return params
}

export const extractWebChatConfigParams = (params: ParamValues, declarations: ParameterDeclaration[]): ParamValues => {
    const parsedParams: ParamValues = { ...params }
    declarations.forEach(d => {
        d.type === ParameterType.String
            ? (parsedParams[d.name] = params[d.name])
            : (parsedParams[d.name] = parseFieldWithArrayCheck(params[d.name], d.type, d.default_value, true))
    })
    return parsedParams
}
