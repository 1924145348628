import { forwardRef, useEffect, useMemo, useState } from "react"
import styles from "./DialogTransferOperatorSearchDropdown.module.scss"
import {
    dialogsApi,
    useLazyGetDialogTransferAvailableQueuesQuery,
    useLazySearchOperatorByCriterionQuery
} from "../../../api/controllers/dialogs"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import {
    DialogTransferOperatorListItem,
    DialogTransferOperatorListItemProps
} from "./DialogTransferOperatorListItem/DialogTransferOperatorListItem"
import { useTranslation } from "react-i18next"
import {
    DialogTransferOperatorSearchFilters,
    TSearchFiltersFormValues
} from "./DialogTransferOperatorSearchFilters/DialogTransferOperatorSearchFilters"
import { selectDialogId, selectSearchOperatorByCriterionPrevArgs } from "../../../store/dialogs/selectors"
import { useParams } from "react-router-dom"
import AsyncQuery from "../../Async/AsyncQuery"
import Spinner from "../../Spinner/Spinner"
import ErrorMessage from "../../ErrorMessage/ErrorMessage"
import { selectGetRolesState } from "../../../store/roles/selectors"
import { getRoles } from "../../../store/roles/thunks"
import { actions } from "../../../store/roles/slice"
import { mapResponseQueuesToGroupedQueue, mapResponseRolesToListRoles } from "../helpers"
import { useSelector } from "react-redux"
import { selectOperatorStatuses } from "../../../store/userOperator/selectors"
import { OperatorStatusValue } from "../../../models/operatorStatus"
import { useProjectSettingCheck } from "../../../utility/common/useProjectSettingCheck"
import { NewWorkplaceOperatorProjectSettings } from "../../../models/projectSettings"
import { SelectOption } from "../../Select/Select"
import { ISearchOperatorsByCriterionResponse } from "../../../models/Dialogs/dialog"

const HIDE_FILTERS_IN_DIALOG_TRANSFER = "HideFiltersInDialogTransfer"

const tNamespace = "dialogs:transfer."
type ProcessedOperator = ISearchOperatorsByCriterionResponse & {
    FullName: string
    IsInactive: boolean
    IsHimSelf: boolean
}

export interface IDialogTransferOperatorSearchDropdownProps {
    onSelectOperator: DialogTransferOperatorListItemProps["onSelect"]
    queryCriterion: string
    includeCurrentUser: boolean
    channelId?: string
    queueId?: string
}

const processOperatorsSearch = (
    operator: ISearchOperatorsByCriterionResponse,
    checkOperatorIsInactive: (operatorStatus: string) => boolean,
    index?: number
): ProcessedOperator => ({
    ...operator,
    FullName: [operator.Lastname, operator.Firstname, operator.Middlename].join(" "),
    IsInactive: checkOperatorIsInactive(operator.Status),
    IsHimSelf: index === 0
})

const sortOperators = (a: ProcessedOperator, b: ProcessedOperator): number => {
    if (a.IsHimSelf && !b.IsHimSelf) {
        return -1
    }

    if (!a.IsHimSelf && b.IsHimSelf) {
        return 1
    }

    if (a.IsInactive !== b.IsInactive) {
        return a.IsInactive ? 1 : -1
    }
    return a.FullName.localeCompare(b.FullName, "ru", { sensitivity: "base" })
}

export const DialogTransferOperatorSearchDropdown = forwardRef<
    HTMLDivElement,
    IDialogTransferOperatorSearchDropdownProps
>((props, innerRef) => {
    const { onSelectOperator, queryCriterion, channelId, includeCurrentUser, queueId } = props
    const { projectId } = useParams<{ projectId: string }>()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [getAvailableQueues, getAvailableQueuesQuery] = useLazyGetDialogTransferAvailableQueuesQuery()

    const selectedDialogId = useAppSelector(selectDialogId) as string
    const { data: dialogData } = useAppSelector(dialogsApi.endpoints.getDialog.select(selectedDialogId))

    const internalProjectId = dialogData?.Project.Id ?? projectId

    const [searchOperatorByCriterionTrigger, searchOperatorByCriterionQuery] = useLazySearchOperatorByCriterionQuery()

    const searchOperatorByCriterionPrevArgs = useAppSelector(selectSearchOperatorByCriterionPrevArgs)

    const handleFiltersFormAutosubmit = (filtersFormData: TSearchFiltersFormValues) =>
        searchOperatorByCriterionTrigger({
            projectId: internalProjectId,
            query: queryCriterion,
            ...filtersFormData,
            includeCurrentUser: includeCurrentUser
        })

    useEffect(() => {
        searchOperatorByCriterionTrigger({
            ...searchOperatorByCriterionPrevArgs,
            query: queryCriterion,
            projectId: internalProjectId,
            includeCurrentUser: includeCurrentUser
        })
    }, [queryCriterion, internalProjectId, includeCurrentUser])

    const rolesAsyncState = useAppSelector(selectGetRolesState)
    const [rolesOptions, setRolesOptions] = useState<SelectOption[]>([])

    const setupOptions = async () => {
        if (!queueId) return

        await getAvailableQueues({
            projectId: internalProjectId,
            channelId,
            queueId
        })
        dispatch(getRoles(internalProjectId))
    }

    useEffect(() => {
        setupOptions()

        return () => {
            dispatch(actions.getRolesReset())
        }
    }, [dispatch, queueId, internalProjectId, channelId])

    useEffect(() => {
        setRolesOptions(mapResponseRolesToListRoles(rolesAsyncState.data))
    }, [rolesAsyncState])

    const { query: _, ...filtersInitial } = searchOperatorByCriterionPrevArgs

    const availableQueuesOptions = useMemo(
        () => mapResponseQueuesToGroupedQueue(getAvailableQueuesQuery.data?.Categories),
        [getAvailableQueuesQuery.data?.Categories]
    )

    const allStatuses = useSelector(selectOperatorStatuses)
    const checkOperatorIsInactive = (operatorStatus: string) => {
        const currentStatus = allStatuses.find(status => status.Description === operatorStatus)
        return currentStatus?.Value === OperatorStatusValue.Offline
    }

    const isFiltersHidden = useProjectSettingCheck<NewWorkplaceOperatorProjectSettings, "HideFiltersInDialogTransfer">(
        HIDE_FILTERS_IN_DIALOG_TRANSFER
    )

    return (
        <div className={styles.dropdown} ref={innerRef}>
            {!isFiltersHidden && (
                <DialogTransferOperatorSearchFilters
                    initialData={filtersInitial}
                    rolesOptions={rolesOptions}
                    availableQueuesOptions={availableQueuesOptions}
                    onSubmitForm={handleFiltersFormAutosubmit}
                />
            )}
            {rolesOptions.length ? (
                <AsyncQuery
                    query={searchOperatorByCriterionQuery}
                    processView={<Spinner size={30} />}
                    emptyDataView={
                        <div className={styles.dropdown__empty}>{t(`${tNamespace}search-operator-not-found`)}</div>
                    }
                    errorView={({ message }) => <ErrorMessage text={message} />}
                    validator={query => !!query.data?.length}
                >
                    {({ data }) => (
                        <div className={styles.dropdown__list}>
                            {data
                                .map((operator, index) =>
                                    processOperatorsSearch(
                                        operator,
                                        checkOperatorIsInactive,
                                        /*
                                         Теперь бэкэнд, при флаге IncludeCurrentUser: true
                                         всегда первым возвращает самого оператора
                                       */
                                        includeCurrentUser ? index : undefined
                                    )
                                )
                                .sort(sortOperators)
                                .map(op => {
                                    return (
                                        <DialogTransferOperatorListItem
                                            key={op.OperatorId}
                                            operator={op}
                                            fullName={op.FullName}
                                            roles={rolesOptions}
                                            onSelect={onSelectOperator}
                                            isInactiveOperator={op.IsInactive}
                                            isHimSelf={op.IsHimSelf}
                                        />
                                    )
                                })}
                        </div>
                    )}
                </AsyncQuery>
            ) : null}
        </div>
    )
})
