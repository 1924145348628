import React, { useEffect, useState } from "react"
import { DebugWebchat } from "../WebchatCard/WebchatCard"
import styles from "./WebchatCard.module.scss"
import channelsController from "../../../../../api/controllers/channels"
import AdvancedSkeleton from "../../../../Skeleton/AdvancedSkeleton"
import ErrorMessage from "../../../../ErrorMessage/ErrorMessage"

export interface WebchatFrameProps {
    debugWebchat: DebugWebchat
    projectId: string
}

const WebchatFrame: React.FC<WebchatFrameProps> = props => {
    const { debugWebchat, projectId } = props
    const [origin, setOrigin] = useState<string>("")
    const url = `${origin}/webchat/scenario/${debugWebchat.channelId}/${debugWebchat.articleId}/${debugWebchat.showInput}`
    const [height, setHeight] = useState<number>()
    const [error, setError] = useState<string>()

    const processIframeMessage = (event: MessageEvent) => {
        const data = event.data
        if (data.notificationType === "WEBCHAT-INFO") {
            setHeight(data.height)
        }
    }

    useEffect(() => {
        channelsController
            .getWebChatAdditionalData(projectId, debugWebchat.channelId)
            .then(data => {
                const host = new URL(data.testPageUrl).origin
                setOrigin(host)
            })
            .catch(e => {
                console.error("Error", e)
                setError(e.message)
            })
    }, [projectId, debugWebchat.channelId])

    useEffect(() => {
        window.addEventListener("message", processIframeMessage)
        return () => {
            window.removeEventListener("message", processIframeMessage)
        }
    }, [])

    return (
        <>
            {error ? (
                <ErrorMessage text={error} />
            ) : origin.length === 0 ? (
                <AdvancedSkeleton className={styles.skeleton} count={1}></AdvancedSkeleton>
            ) : (
                <iframe className={styles.webchatFrame} height={height} src={url}></iframe>
            )}
        </>
    )
}

export default WebchatFrame
