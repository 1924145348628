import { push } from "connected-react-router"
import { useCallback, useMemo } from "react"
import { shallowEqual } from "react-redux"
import { useRouteMatch } from "react-router-dom"
import { SearchQueryArticles } from "../.."
import { ArticleAction } from "../../../../models/article"
import { useAppDispatch, useAppSelector } from "../../../../store/hooks"
import { selectCatalogsMap, selectSearchArticlesState } from "../../../../store/knowledgeBase/selectors"
import { clearCurrentBranch } from "../../../../store/knowledgeBase/slice"
import { buildArticleActionUrl, buildSharedArticleActionUrl } from "../../../../utility/knowledgeBase/articleUrlHelper"
import Async from "../../../Async/Async"
import { CatalogPlacement } from "../../../Catalogs/Catalogs"
import ErrorMessage from "../../../ErrorMessage/ErrorMessage"
import { SearchQueryArticlesLoader } from "../Loader"
import { extractSymbolCodesFromCatalogsMap, filterSearchData } from "./helpers"
import { ItemId } from "@atlaskit/tree/types"

interface SearchQueryArticlesContainerProps {
    shareId?: string
    catalogPlacement?: CatalogPlacement
    onArticleClick?: (code: string) => void
    allowedCatalogs?: ItemId[]
}

export const SearchQueryArticlesContainer = (props: SearchQueryArticlesContainerProps) => {
    const { shareId, catalogPlacement, onArticleClick, allowedCatalogs } = props
    const { url } = useRouteMatch()
    const dispatch = useAppDispatch()
    const searchArticlesState = useAppSelector(selectSearchArticlesState, shallowEqual)
    const catalogsMap = useAppSelector(selectCatalogsMap)
    const isNotKB = catalogPlacement !== CatalogPlacement.knowledgeBase

    const allowedArticleSymbolCodes = useMemo(
        () => extractSymbolCodesFromCatalogsMap(allowedCatalogs, catalogsMap),
        [allowedCatalogs, catalogsMap]
    )

    const handleArticleClick = useCallback(
        (articleCode: string) => {
            dispatch(clearCurrentBranch())
            if (shareId) {
                dispatch(push(buildSharedArticleActionUrl(url, articleCode)))
            } else {
                if (isNotKB) {
                    onArticleClick && onArticleClick(articleCode)
                } else {
                    dispatch(push(buildArticleActionUrl(url, ArticleAction.View, articleCode)))
                }
            }
        },
        [dispatch, shareId, url, onArticleClick, catalogPlacement]
    )

    return (
        <Async
            dataState={searchArticlesState}
            processView={<SearchQueryArticlesLoader />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: { Articles, Highlights, Scores } }) => {
                const { articles, highlights, scores } = filterSearchData(
                    Articles,
                    Highlights,
                    Scores,
                    isNotKB,
                    allowedArticleSymbolCodes
                )
                return (
                    <SearchQueryArticles
                        articles={articles}
                        highlights={highlights}
                        scores={scores}
                        onArticleClick={handleArticleClick}
                    />
                )
            }}
        </Async>
    )
}
