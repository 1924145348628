import { EditEnum, Message, MessageType } from "../../../models/Dialogs/message"

const messageRerouteTypes = [MessageType.OperatorRerouteDialog, MessageType.OperatorRerouteDialogConfirmation]

export const processTaskMessages = (messages: Message[]) => {
    return messages.reduce<Map<string, Message>>((acc, message) => {
        if (messageRerouteTypes.includes(message.MsgType)) {
            return acc
        }

        const parentId = message.ParentMessageId
        if (message.MsgType === MessageType.MessageDeleted && parentId && acc.has(parentId)) {
            const currentValue = acc.get(parentId)
            if (currentValue) {
                acc.set(parentId, {
                    ...currentValue,
                    EditType: EditEnum.Deleted
                })
            }

            return acc
        }
        if (message.MsgType === MessageType.MessageEdited && parentId && acc.has(parentId)) {
            const currentValue = acc.get(parentId)
            if (currentValue) {
                acc.set(parentId, {
                    ...currentValue,
                    Attachment: message.Attachment,
                    Text: message.Text,
                    Sticker: message.Sticker,
                    EditType: EditEnum.Edited
                })
            }
            return acc
        }
        acc.set(message.MsgId, message)
        return acc
    }, new Map<string, Message>())
}
