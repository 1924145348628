import React from "react"
import { Task } from "../../models/task"
import { mapChannelTypeToClassName } from "../../utility/channels/channel"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faProjectDiagram } from "@fortawesome/pro-light-svg-icons/faProjectDiagram"
import cn from "classnames"
import "./TaskCard.scss"
import ChannelIcon from "../ChannelIcon/ChannelIcon"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { formatDistanceStrictLocal } from "../../utility/common/time"
import { channelTypeStringConverter } from "../../utility/channels/channelTypeStringConverter"
import { useTranslation } from "react-i18next"
import { textDomParser } from "../../utility/common/textDomParser"
import { actions } from "../../store/queues/slice"
import { useAppDispatch } from "../../store/hooks"
import useReplaceEmojisShortcodes from "../../utility/common/useReplaceEmojiShortcodes"

const CHANNEL_TYPE_PREFIX = "task-card-channel_"
const CLIENT_ID_LINK_TYPE_PREFIX = "task-card-client_"
const CHANNEL_NAME_MAX_LENGTH = 26
export interface ClientLink {
    type: "project" | "queue" | "unknown"
    itemId: string
    text: string
}
export interface TaskCardProps {
    task: Task
    channelName?: string
    link?: ClientLink
    onClick?: (task: Task) => void
    selected?: boolean
}

const TaskCard: React.FC<TaskCardProps> = props => {
    const { task, channelName, link, onClick, selected = false } = props
    const ageStr = formatDistanceStrictLocal(task.CreatedAt)
    const channelType = channelTypeStringConverter.toChannelType(task.Channel.Type)
    const dispatch = useAppDispatch()

    const handleClick = () => {
        if (onClick === undefined) {
            return
        }
        dispatch(actions.setIsDialogTransferSidebarOpened(false))
        onClick(task)
    }
    const { t } = useTranslation()
    const title = channelName || task.Channel.Title
    const previewText = task.Preview?.Text ?? ""
    const previewMessage = previewText === "task:form.preview-attachment" ? t(previewText) : previewText
    const parsedMessage = textDomParser(previewMessage)
    const parsedMessageWithCorrectEmojis = useReplaceEmojisShortcodes(parsedMessage)

    return (
        <div
            className={cn(
                "task-card",
                selected && "task-card_selected",
                link && link.type === "unknown" && "task-card_unknown"
            )}
            onClick={handleClick}
        >
            <TooltipTrigger
                id={task.Id}
                placement="top"
                content={title}
                condition={typeof title === "string" && title.length > CHANNEL_NAME_MAX_LENGTH}
            >
                <div
                    className={cn(
                        "task-card__channel task-card-channel",
                        mapChannelTypeToClassName(channelType, CHANNEL_TYPE_PREFIX)
                    )}
                >
                    <div className="task-card-channel__icon task-card-channel-icon">
                        <ChannelIcon type={channelType} className="task-card-channel-icon__item" />
                    </div>
                    <div className="task-card-channel__name">{title}</div>
                </div>
            </TooltipTrigger>
            <div className={cn("task-card__client task-card-client", getLinkClassName(props.link))}>
                {props.link && (
                    <div className="task-card-client__icon task-card-client-icon">
                        <FontAwesomeIcon icon={faProjectDiagram} className="task-card-project-icon" />
                    </div>
                )}
                <div className="task-card-client__name">
                    {link ? link.text : `${task.Client.FirstName} ${task.Client.LastName}`}
                </div>
            </div>
            <div className="task-card__message">{parsedMessageWithCorrectEmojis}</div>
            <div className="task-card__info task-card-info">
                <div className="task-card-info__date">{ageStr}</div>
                <div className="task-card-info__status" />
            </div>
        </div>
    )
}

const getLinkClassName = (link?: ClientLink) => {
    if (!link) {
        return ""
    }
    switch (link.type) {
        case "queue":
        case "project":
            return `${CLIENT_ID_LINK_TYPE_PREFIX}link`
        case "unknown":
            return `${CLIENT_ID_LINK_TYPE_PREFIX}disabled`
    }
}

export default TaskCard
