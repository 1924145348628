import { useTranslation } from "react-i18next"
import { colorByName } from "../../../../helpers/color"
import { ISearchOperatorsByCriterionResponse } from "../../../../models/Dialogs/dialog"
import { SystemProjectSettings } from "../../../../models/projectSettings"
import { useProjectSettingCheck } from "../../../../utility/common/useProjectSettingCheck"
import TooltipTrigger from "../../../TooltipTrigger/TooltipTrigger"
import styles from "./DialogTransferOperatorListItem.module.scss"
import cn from "classnames"
import { SelectOption } from "../../../Select/Select"

const tNamespace = "queues:settings"
const testId = "dialogTransferOperatorListItem"
const DEFAULT_COLOR = "#BDBDBD"

type TSelectedSearchOperator = {
    OperatorId: string
    Fullname: string
}

export interface DialogTransferOperatorListItemProps {
    operator: ISearchOperatorsByCriterionResponse
    roles: SelectOption[]
    onSelect: (operator: TSelectedSearchOperator) => void
    isInactiveOperator: boolean
    isHimSelf?: boolean
    fullName: string
}

export const DialogTransferOperatorListItem: React.FC<DialogTransferOperatorListItemProps> = props => {
    const {
        onSelect,
        roles = [],
        operator: { OperatorId, Firstname, Lastname, Middlename, RoleId },
        isInactiveOperator,
        isHimSelf,
        fullName: Fullname
    } = props

    const { t } = useTranslation()
    const roleTitle = roles.find(x => x.value === RoleId)?.label
    const shortName = [Lastname[0], Firstname[0]].join("").toUpperCase()
    const isDisplayID = useProjectSettingCheck<SystemProjectSettings, "DisplayOperatorIdEnabled">(
        "DisplayOperatorIdEnabled"
    )
    const isDisplayRole = useProjectSettingCheck<SystemProjectSettings, "DisplayOperatorRoleEnabled">(
        "DisplayOperatorRoleEnabled"
    )

    const handleSelectOperator = () => {
        onSelect({
            Fullname,
            OperatorId
        })
    }

    return (
        <TooltipTrigger
            key={OperatorId}
            id={`tooltip-${OperatorId}`}
            content={t(`${tNamespace}.not-available-operator`)}
            placement="top"
            condition={isInactiveOperator}
        >
            <div
                className={styles.item}
                onClick={!isInactiveOperator ? handleSelectOperator : undefined}
                data-testid={testId}
            >
                <div
                    className={styles.item__thumb}
                    style={{ backgroundColor: isInactiveOperator ? DEFAULT_COLOR : colorByName(shortName) }}
                >
                    {shortName}
                </div>
                <div className={styles.item__info}>
                    <div className={cn(styles.item__title, isInactiveOperator && styles.item__title_muted)}>
                        {Fullname}
                    </div>
                    {isDisplayRole && (
                        <div className={styles.item__role}>{!isHimSelf ? roleTitle : t(`${tNamespace}.to-myself`)}</div>
                    )}
                    {isDisplayID && <div className={styles.item__id}>{OperatorId}</div>}
                </div>
            </div>
        </TooltipTrigger>
    )
}
