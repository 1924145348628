import { DialogOperatorRerouteConfirmation } from "../../../../../models/Dialogs/message"
import { memo } from "react"
import cn from "classnames"
import styles from "./MessageRerouteDialog.module.scss"
import { useTranslation } from "react-i18next"
import { testId } from "../../../../../utility/tests/testId"
import ChannelIcon from "../../../../ChannelIcon/ChannelIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faClock, faLongArrowRight, faUser, faUserCog } from "@fortawesome/pro-light-svg-icons"
import { ChannelType } from "../../../../../models/channel"
import { RerouteTime } from "./RerouteTime"

export type MessageRerouteDialogProps = {
    message: DialogOperatorRerouteConfirmation
    className?: string
    lastOperatorDialogRerouteMessage?: DialogOperatorRerouteConfirmation
    isColumnAlign?: boolean
    isConfirmation?: boolean
}

const ChannelInfo = ({ channelName }: { channelName: string }) => (
    <>
        <ChannelIcon className={styles.message__icon} type={ChannelType.WebChat} />
        {channelName}
        &nbsp;
    </>
)

const OperatorInfo = ({
    operatorName,
    isTimeout,
    timeoutTitle,
    isSupervisor
}: {
    operatorName?: string
    isTimeout?: boolean
    timeoutTitle?: string
    isSupervisor?: boolean
}) => {
    const icon = isTimeout ? faClock : isSupervisor ? faUserCog : faUser

    return operatorName ? (
        <>
            <FontAwesomeIcon
                icon={icon}
                className={cn(styles.message__iconPerson, isSupervisor && styles.message__iconSupervisor)}
            />
            {isTimeout ? timeoutTitle : operatorName}
            &nbsp;
        </>
    ) : null
}

const tNamespace = "dialogs"

export const MessageRerouteDialog = memo((props: MessageRerouteDialogProps) => {
    const { message, className, lastOperatorDialogRerouteMessage, isColumnAlign, isConfirmation } = props
    const { t } = useTranslation(tNamespace)
    const toQueue = message.To.QueueName
    const fromQueue = message.From.QueueName
    const isSupervisor = message.IsSupervisor

    const isTimeout =
        lastOperatorDialogRerouteMessage && message.To.QueueName !== lastOperatorDialogRerouteMessage.To?.QueueName
    const showQueueFrom = fromQueue !== toQueue || isSupervisor || !message.From.OperatorId

    const operatorFrom = (
        <OperatorInfo
            operatorName={message.From.OperatorId}
            isTimeout={isTimeout}
            timeoutTitle={t("messages.timeout")}
            isSupervisor={isSupervisor}
        />
    )

    const operatorTo = <OperatorInfo operatorName={message.To.OperatorId} />

    const channelTo = (
        <>
            <ChannelInfo channelName={toQueue} />
            <RerouteTime timestamp={message.Timestamp} />
        </>
    )

    if (isColumnAlign) {
        return (
            <div
                className={cn(styles.message, styles.taskCardContainer, className)}
                data-testid={testId.messageReroute}
            >
                <div className={styles.message__text}>
                    <div>
                        {operatorFrom}
                        {!showQueueFrom && <FontAwesomeIcon icon={faLongArrowRight} className={styles.message__icon} />}
                    </div>

                    {showQueueFrom ? (
                        <div className={styles.channel__text}>
                            <ChannelInfo channelName={fromQueue} />
                            <FontAwesomeIcon icon={faLongArrowRight} className={styles.message__icon} />
                        </div>
                    ) : (
                        ""
                    )}

                    <div>{operatorTo}</div>
                    <div className={styles.channel__text}>{channelTo}</div>
                </div>
            </div>
        )
    }

    return (
        <div
            className={cn(styles.message, isConfirmation && styles.message_confirmation, className)}
            data-testid={testId.messageReroute}
        >
            <div className={styles.message__text}>
                {operatorFrom}
                {showQueueFrom ? <ChannelInfo channelName={fromQueue} /> : ""}
                <FontAwesomeIcon icon={faLongArrowRight} className={styles.message__icon} />
                {operatorTo}
                {channelTo}
            </div>
        </div>
    )
})
