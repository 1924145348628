import React, { useCallback } from "react"
import { faFileAlt } from "@fortawesome/pro-light-svg-icons/faFileAlt"
import IconButton from "../IconButton/IconButton"
import { TreeItem } from "@atlaskit/tree/types"
import ScalableList from "../ScalableList/ScalableList"
import { selectArticleExpanded } from "../../store/knowledgeBase/selectors"
import cn from "classnames"
import styles from "./SubArticles.module.scss"
import { SUB_ARTICLES_LIST_MAX_LENGTH, SUB_ARTICLES_LOCALES, testId } from "./SubArticles.const"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "../../store/hooks"

export type SubArticlesProps = {
    subArticles: TreeItem[]
    onSubArticleClick: (category: TreeItem) => void
}

const { tNamespace, tPath } = SUB_ARTICLES_LOCALES

const SubArticles = (props: SubArticlesProps) => {
    const { subArticles, onSubArticleClick } = props
    const { t } = useTranslation(tNamespace)
    const isArticleExpanded = useAppSelector(selectArticleExpanded)

    const handleSubArticleClick = useCallback(
        (subArticle: TreeItem) => {
            return () => {
                onSubArticleClick(subArticle)
            }
        },
        [onSubArticleClick]
    )

    const getContentItem = (subArticle: TreeItem) => {
        return (
            <IconButton
                key={subArticle.id}
                testId={testId.subArticleItem}
                className={styles.subArticles__item}
                variant="link"
                icon={faFileAlt}
                iconSize="lg"
                onClick={handleSubArticleClick(subArticle)}
            >
                {subArticle.data.title}
            </IconButton>
        )
    }

    return (
        <div className={cn(styles.subArticles, isArticleExpanded && styles.subArticles_expanded)}>
            <h4>{t(tPath.subArticles)}</h4>
            <ScalableList
                t={t}
                items={subArticles}
                limit={SUB_ARTICLES_LIST_MAX_LENGTH}
                mainContainerClassName={styles.subArticles__body}
            >
                {getContentItem}
            </ScalableList>
        </div>
    )
}

export default SubArticles
