import React from "react"
import { shallowEqual } from "react-redux"
import { selectRelatedCategoriesState } from "../../../store/knowledgeBase/selectors"
import SubArticles from "../SubArticles"
import ErrorMessage from "../../ErrorMessage/ErrorMessage"
import Async from "../../Async/Async"
import CategoriesLoader from "../../CategoryContainer/CategoriesLoader"
import { TreeItem } from "@atlaskit/tree/types"
import styles from "./SubArticlesContainer.module.scss"
import { useAppSelector } from "../../../store/hooks"

type SubArticlesContainerProps = {
    onSubArticleClick: (category: TreeItem) => void
}

const SubArticlesContainer = (props: SubArticlesContainerProps) => {
    const relatedArticlesState = useAppSelector(selectRelatedCategoriesState, shallowEqual)

    return (
        <Async
            dataState={relatedArticlesState}
            processView={
                <div className={styles.container__loader}>
                    <CategoriesLoader itemsCount={3} />
                </div>
            }
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: { ChildCategories } }) => (
                <>{!!ChildCategories?.length && <SubArticles {...props} subArticles={ChildCategories} />}</>
            )}
        </Async>
    )
}

export default SubArticlesContainer
