import React from "react"
import { AgentsOptions, AgentType, JSAgentValues, RoutingAgentValues } from "../../../../../models/agent"
import { useSelector } from "react-redux"
import { selectAgents } from "../../../../../store/agents/selectors"
import { nameof } from "../../../../../utility/common/nameof"
import AgentFormWrapper from "../../../../AgentFormWrapper/AgentFormWrapper"
import { JSAgentFormProps } from "../JSAgentForm"
import CodeBlockValidatableInput from "../../../../CodeBlockValidatableInput/CodeBlockValidatableInput"
import DebouncedValidatableInput from "../../../../ValidatableInput/DebouncedValidatableInput"
import { formTranslation } from "../../../../../locales/form"
import ValidatableInput from "../../../../ValidatableInput/ValidatableInput"
import { Form } from "react-bootstrap"
import InfoIcon from "../../../../InfoIcon/InfoIcon"
import FormSection from "../../../../FormHelpers/Section/FormSection"
import DebouncedCheckBox from "../../../../CheckBoxValidatableInput/DebouncedCheckBox"
import RemovableListItem from "../../../../FormHelpers/RemovableListItem/RemovableListItem"
import MultipleSelectControl from "../../../../FormHelpers/MultipleSelectControl/MultipleSelectControl"
import styles from "./JSAgentAdvanced.module.scss"
import { JSAGENT_IS_MODULE_KEY } from "../../../../../utility/agents/javascriptAgentValues"

const tNamespace = "agent:form."
const tNamespaceJSAgent = "agent:form.jsagent."

const FormikJSAgentAdvanced: React.FC<JSAgentFormProps> = props => {
    const { agent, t, handleSubmit, disabled, inProcess, values, initialValues, onCustomInputChange } = props

    const agents = useSelector(selectAgents)

    const agentsOptions = (agents ?? []).reduce(
        (agentsOptions: AgentsOptions, storedAgent) => {
            const isAgentExistAndNotCurrent = agent && storedAgent.Id !== agent.Id
            const isJsAgentModule =
                storedAgent.Type === AgentType.JSAgent && storedAgent.Params[JSAGENT_IS_MODULE_KEY] === "true"
            const isNotIncludedJsAgentModule = !values.Modules.includes(storedAgent.Id)
            const isAiAssist = storedAgent.Type === AgentType.AIAssist2

            if (!isAgentExistAndNotCurrent) {
                return agentsOptions
            }

            const agentOption = {
                label: storedAgent.Id,
                value: storedAgent.Id
            }

            agentsOptions.all.push(agentOption)
            if (isAiAssist) {
                agentsOptions.aiassists.push(agentOption)
            } else if (isJsAgentModule && isNotIncludedJsAgentModule) {
                agentsOptions.jsagentModules.push(agentOption)
            }

            return agentsOptions
        },
        { all: [], jsagentModules: [], aiassists: [] }
    )

    return (
        <AgentFormWrapper onSubmit={handleSubmit} disabled={disabled} loading={inProcess}>
            <div className="agent-form__section">
                <FormSection>
                    <div className={styles.isModuleSettingsWrapper}>
                        <DebouncedCheckBox id={"formIsModule"} name={nameof<JSAgentValues>("IsModule")} />
                        <Form.Label>
                            {t(`${tNamespaceJSAgent}is-module`)}
                            <InfoIcon
                                id="formIsModuleDescription"
                                content={t(`${tNamespaceJSAgent}is-module-description`)}
                            />
                        </Form.Label>
                    </div>
                </FormSection>
                <MultipleSelectControl
                    id="formModules"
                    label={t(`${tNamespaceJSAgent}modules`)}
                    name={nameof<JSAgentValues>("Modules")}
                    options={agentsOptions.jsagentModules}
                    selectedItems={remove => (
                        <>
                            {values.Modules.map((v, index) => (
                                <RemovableListItem key={index} onDelete={() => remove(index)}>
                                    {v}
                                </RemovableListItem>
                            ))}
                        </>
                    )}
                    onSelect={(inputName, option) =>
                        onCustomInputChange && onCustomInputChange(inputName, option, initialValues)
                    }
                    disabled={!agentsOptions.jsagentModules?.length || values.IsModule}
                />
                <CodeBlockValidatableInput
                    id={"formSettings"}
                    label={t(`${tNamespaceJSAgent}additional-settings`)}
                    name={nameof<JSAgentValues>("Settings")}
                    language={"json"}
                />
                <DebouncedValidatableInput
                    as="select"
                    id="formAIAgent"
                    name={nameof<JSAgentValues>("AIAgent")}
                    label={t(`${tNamespaceJSAgent}ai-agent`)}
                >
                    {agentsOptions.aiassists.map(option => (
                        <option value={option.value} key={option.value}>
                            {option.label}
                        </option>
                    ))}
                </DebouncedValidatableInput>
                <ValidatableInput
                    as="select"
                    id="formTitle"
                    type="text"
                    name={nameof<RoutingAgentValues>("FailOverAgent")}
                    label={t(`${tNamespace}agent-if-failure-current`)}
                    disabled={!agentsOptions.all.length}
                >
                    <option value="">{t(formTranslation.nothingSelected)}</option>
                    {agentsOptions.all.map(option => (
                        <option value={option.value} key={option.value}>
                            {option.label}
                        </option>
                    ))}
                </ValidatableInput>
            </div>
        </AgentFormWrapper>
    )
}

export default FormikJSAgentAdvanced
