import { faCheck, faPauseCircle } from "@fortawesome/pro-light-svg-icons"
import cn from "classnames"
import { format } from "date-fns"
import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import Can from "../../../../../../components/Can/Can"
import IconButton from "../../../../../../components/IconButton/IconButton"
import { formTranslation } from "../../../../../../locales/form"
import { MassMailingUserStatuses, SendingJob } from "../../../../../../models/massMailing"
import { ModifyMassMailing } from "../../../../../../permissions"
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks"
import { deleteMassMailing, pauseMassMailing, startMassMailing } from "../../../../../../store/massMailing/thunks"
import { selectCurrentProjectId } from "../../../../../../store/projects/selectors"
import { Time } from "../../../../../../utility/common/time"
import { testId } from "../../../../../../utility/tests/testId"
import { usersController } from "./../../../../../../api/controllers/users"
import { MASS_MAILING_VIEW_HEADER_CONST } from "./MassMailingViewHeader.const"
import styles from "./MassMailingViewHeader.module.scss"
import { AdvancedSkeleton } from "./../../../../../../components/AdvancedSkeleton/AdvancedSkeleton"
import { logError } from "../../../../../../utility/common/logError"

const { tNamespace, tPaths } = MASS_MAILING_VIEW_HEADER_CONST

export interface MassMailingViewHeaderProps {
    className: string
    openReportsSidebar: () => void
    status: MassMailingUserStatuses
    mailing: SendingJob
}

export const MassMailingViewHeader = (props: MassMailingViewHeaderProps) => {
    const { className, openReportsSidebar, status, mailing } = props

    const { t } = useTranslation(tNamespace)
    const dispatch = useAppDispatch()
    const projectId = useAppSelector(selectCurrentProjectId)
    const [names, setNames] = useState("")

    const isInactive = status === MassMailingUserStatuses.sheduled || status === MassMailingUserStatuses.paused
    const tDatePrefix = mailing.Audit.DeleteByUserId
        ? tPaths.deleted
        : mailing.Audit.UpdateByUserId
        ? tPaths.updated
        : tPaths.created
    const date = format(
        mailing.Audit.DeleteTimestamp || mailing.Audit.UpdateTimestamp || mailing.Audit.CreateTimestamp,
        "HH:mm dd.MM.yyyy"
    )
    const author = mailing.Audit.DeleteByUserId || mailing.Audit.UpdateByUserId || mailing.Audit.CreateByUserId

    const title = `${mailing.Data.Title} - ${Time.stampToLocalDate(
        mailing.Data.ScheduleTime ?? mailing.Audit.CreateTimestamp
    )}`

    useEffect(() => {
        if (projectId) {
            getNames(author, projectId)
        }
    }, [author, projectId])

    const getNames = async (author: string, projectId: string) => {
        try {
            const res = await usersController.getUsersByLogins(projectId, { Logins: [author] })
            if (res[0] && res[0].Login === author) {
                setNames(res[0].LastName + " " + res[0].FirstName)
            } else {
                setNames(t(tPaths.unknown))
            }
        } catch (error) {
            logError(error)
            setNames(t(tPaths.error))
        }
    }

    return (
        <div className={cn(className, styles.header)}>
            <h1 className={styles.header__title}>{title}</h1>
            <div className={styles.header__status}>
                <div className={cn(styles.header__indicator, styles[`header__indicator_${status}`])} />
                {t(`${tPaths.status}${status}`)}
            </div>
            <div className={styles.header__btns}>
                <Can permission={ModifyMassMailing}>
                    {isInactive && (
                        <Button
                            variant="primary"
                            onClick={() => dispatch(deleteMassMailing(mailing))}
                            data-testid={testId.deleteMassMailing}
                        >
                            {t(formTranslation.delete)}
                        </Button>
                    )}
                    {status === MassMailingUserStatuses.paused && (
                        <IconButton
                            variant="outline-primary"
                            icon={faCheck}
                            onClick={() => dispatch(startMassMailing(mailing))}
                            testId={testId.startMassMailing}
                        >
                            {t(tPaths.start)}
                        </IconButton>
                    )}
                    {status === MassMailingUserStatuses.active && (
                        <IconButton
                            variant="outline-primary"
                            icon={faPauseCircle}
                            onClick={() => dispatch(pauseMassMailing(mailing))}
                            testId={testId.pauseMassMailing}
                        >
                            {t(tPaths.pause)}
                        </IconButton>
                    )}
                </Can>
                {status !== MassMailingUserStatuses.sheduled && (
                    <Button
                        variant="outline-primary"
                        onClick={openReportsSidebar}
                        data-testid={testId.openMassMailingReports}
                    >
                        {t(tPaths.reports)}
                    </Button>
                )}
            </div>
            <div className={styles.header__audit}>
                <div>
                    {t(tDatePrefix)} {date}
                </div>
                <div className={styles.header__author}>
                    {t(tPaths.author)}: {names ? names : <AdvancedSkeleton width="100px" height="21px" />}
                </div>
            </div>
        </div>
    )
}
