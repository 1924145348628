import { IGetDialogTransferAvailableQueues } from "../../models/Dialogs/dialog"
import { Role } from "../../models/role"
import { SelectOption } from "../Select/Select"

export type TGroupedQueueOption = SelectOption & {
    options: SelectOption[]
}

export type TGroupedQueuesReturn = Array<TGroupedQueueOption>

export const mapResponseQueuesToGroupedQueue = (
    income: IGetDialogTransferAvailableQueues["Categories"] = []
): TGroupedQueuesReturn =>
    income.map(category => {
        const isDefaultQueueOnly = category.Queues.length === 1 && category.Queues[0].IsDefault

        // добавляем в options поле noGroup чтобы форматировать очереди без групп
        return {
            label: category.Name,
            value: category.Id,
            options: category.Queues.map(queue => ({
                value: queue.Id,
                label: queue.Name,
                extra: {
                    noGroup: isDefaultQueueOnly
                }
            })).sort((a, b) => a.label.localeCompare(b.label, "ru", { sensitivity: "base" })),
            extra: {
                noGroup: isDefaultQueueOnly
            }
        }
    })

export const mapResponseRolesToListRoles = (income: Role[] = []): SelectOption[] =>
    income.map(x => ({
        label: x.Title,
        value: x.Id
    }))
