import { ArticleView, SearchArticlesHighlights, SearchArticlesScores } from "../../../../models/article"
import { ItemId } from "@atlaskit/tree/types"
import { CategoryTreeData } from "../../../../models/category"

export function filterSearchData(
    articles: ArticleView[],
    highlights: SearchArticlesHighlights | undefined,
    scores: SearchArticlesScores | undefined,
    isNotKB: boolean,
    allowedArticleSymbolCodes?: string[]
) {
    if (!isNotKB) {
        return { articles, highlights, scores }
    }

    let filteredArticles = articles.filter(article => !article.Scenario)

    if (allowedArticleSymbolCodes) {
        filteredArticles = filteredArticles.filter(article => allowedArticleSymbolCodes.includes(article.SymbolCode))
    }

    const filteredHighlights = Object.fromEntries(
        Object.entries(highlights || {}).filter(([key]) => filteredArticles.some(article => article.Id === key))
    )

    const filteredScores = Object.fromEntries(
        Object.entries(scores || {}).filter(([key]) => filteredArticles.some(article => article.Id === key))
    )

    return {
        articles: filteredArticles,
        highlights: filteredHighlights,
        scores: filteredScores
    }
}

export const extractSymbolCodesFromCatalogsMap = (
    allowedCatalogs?: ItemId[],
    catalogsMap?: Record<string, CategoryTreeData>
) => {
    return catalogsMap && allowedCatalogs
        ? allowedCatalogs
              .map(catalogId => {
                  return Object.keys(catalogsMap[catalogId].items).map(
                      articleId => catalogsMap[catalogId].items[articleId].data.symbolCode
                  )
              })
              .flat()
        : undefined
}
