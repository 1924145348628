import Peer, { MediaConnection } from "peerjs"
import { Socket } from "socket.io-client"
import { FinishReason } from "../../../../models/screensharing"

export enum CallMode {
    Videocall = "Videocall",
    Screensharing = "Screensharing"
}

export interface VideoCallState {
    socketRef?: Socket
    peerRef?: Peer
    clientStreamRef?: MediaStream
    operatorStreamRef?: MediaStream
    callMode?: CallMode
    isOperatorAudioEnabled?: boolean
    isOperatorVideoEnabled?: boolean
    isControlEnabled?: boolean
    currentRoomId?: string
    currentOperatorId?: string
    currentCall?: MediaConnection
    currentClientId?: string
    isCollapsed: boolean
    sessionStatus: SessionStatus
}

export interface RoomMetaData {
    roomId: string
    userId: string
}

export enum SessionStatus {
    Initial = "Initial",
    Loading = "Loading",
    Connected = "Connected",
    ConnectError = "ConnectError"
}

export enum EndCallSource {
    default = "default",
    websocketEndCallEvent = "websocketEndCallEvent",
    websocketDisconnectEvent = "websocketDisconnectEvent",
    unMountComponent = "unMountComponent",
    initConnectionError = "initConnectionError"
}

export interface ScreensharingLocalStorageState {
    callMode: CallMode
}

export enum StatisticsMessageType {
    CobrowsingRequest = "CobrowsingRequest",
    VideocallRequest = "VideocallRequest",
    RequestTimeout = "RequestTimeout",
    SessionFinished = "SessionFinished",
    FinishReason = "FinishReason"
}

type BaseStatisticsMessage = {
    Type: StatisticsMessageType
    Reason?: FinishReason
}

export type SendStatisticsMessageParams = BaseStatisticsMessage &
    (
        | { Type: StatisticsMessageType.FinishReason; Reason: FinishReason }
        | { Type: Exclude<StatisticsMessageType, StatisticsMessageType.FinishReason> }
    )
