export const MASS_MAILING_VIEW_HEADER_CONST = {
    tNamespace: "massMailing",
    tPaths: {
        status: "status.",
        start: "buttons.start",
        pause: "buttons.pause",
        reports: "buttons.reports",
        created: "audit.created",
        updated: "audit.updated",
        deleted: "audit.deleted",
        author: "audit.author",
        error: "audit.error",
        unknown: "audit.unknown"
    }
}
