import React from "react"
import type { NotificationContentFunction } from "../../utility/notifications/notificationContentType"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getIconByType } from "../../utility/notifications/getIcon"
import { faCheck } from "@fortawesome/pro-light-svg-icons/faCheck"
import styles from "./NotificationItem.module.scss"
import { LocalizedText } from "../../types/localizedText"
import { CSS_VAR_COLORS } from "../../helpers/color"
import truncate from "lodash/truncate"

const MAX_TITLE_LENGTH = 500

const NotificationDefaultItem: NotificationContentFunction = (notification, t) => {
    const localizeIfNeeded = (payload: LocalizedText) => (payload.NeedLocalization ? t(payload.Value) : payload.Value)
    const titleMessage = localizeIfNeeded(notification.Title)
    const descriptionMessage = notification.Description && localizeIfNeeded(notification.Description)
    const iconContent = (
        <>
            <FontAwesomeIcon
                color={notification.Type === "error" ? CSS_VAR_COLORS.DANGER : CSS_VAR_COLORS.PRIMARY}
                icon={getIconByType(notification.Type)}
            />
            {notification.NeedApprove && (
                <div className={styles.notificationItemContainer__needApprove}>
                    <FontAwesomeIcon color={CSS_VAR_COLORS.PRIMARY} icon={faCheck} />
                </div>
            )}
        </>
    )
    const mainContent = (
        <>
            <div className={styles["notificationItemContainer__title"]}>
                {truncate(titleMessage, { length: MAX_TITLE_LENGTH, omission: "..." })}
            </div>
            {descriptionMessage && (
                <div className={styles["notificationItemContainer__description"]}>{descriptionMessage}</div>
            )}
        </>
    )
    const buttons = <></>
    return {
        iconContent,
        mainContent,
        buttons
    }
}

export default NotificationDefaultItem
