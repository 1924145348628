import { useEffect, useState } from "react"
import { replaceAllShortcodes } from "./emoji"

const useReplaceEmojisShortcodes = (text: string) => {
    const [emojifiedText, setEmojifiedText] = useState(text)

    useEffect(() => {
        const emojifyText = async () => {
            const shortcodePattern = /:(\w+):/g
            const isAnyShortcodesEmojis = text.match(shortcodePattern)

            if (isAnyShortcodesEmojis) {
                const replacedText = await replaceAllShortcodes(text)

                setEmojifiedText(replacedText)
            } else {
                setEmojifiedText(text)
            }
        }

        emojifyText()
    }, [text])

    return emojifiedText
}

export default useReplaceEmojisShortcodes
