import { Formik, FormikProps } from "formik"
import { Form, Tab } from "react-bootstrap"
import { preventSubmitOnEnter } from "../../utility/common/preventSubmitOnEnter"
import { useTranslation } from "react-i18next"
import { useActions } from "../../hooks/useAction"
import LoadingButton from "../LoadingButton/LoadingButton"
import styles from "./DialogTransferModal.module.scss"
import { DIALOG_TRANSFER_PORTAL_ID } from "./DialogTransferModal"
import { DialogTransferOperatorSearch } from "./DialogTransferOperatorSearch"
import CreatableGroupedTagSelect from "../CreatableTagSelect/CreatableGroupedTagSelect"
import { validationSchema } from "./validationSchema"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { FormikFormObserver } from "../../helpers/formik"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
    dialogsApi,
    useLazyGetDialogTransferAvailableQueuesByOperatorQuery,
    useLazyGetDialogTransferAvailableQueuesQuery
} from "../../api/controllers/dialogs"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { selectDialogId } from "../../store/dialogs/selectors"
import { selectDefaultQueueIfNotRouted, selectHideChoiceQueueIfNotRouted } from "../../store/projects/selectors"
import { testId } from "../../utility/tests/testId"
import Tabs from "../Tabs/Tabs"
import cn from "classnames"
import LazyCatalog from "../Catalog/LazyCatalog"
import { handleIcon } from "../QueueCategories/QueueCategories"
import type { ItemId } from "@atlaskit/tree/types"
import { toggleQueueCategoryForReroute } from "../../store/queues/slice"
import { selectQueueCategoriesForReroute } from "../../store/queues/selectors"
import { TreeItem } from "@atlaskit/tree"
import { viewAllQueues } from "../../store/queues/actions"
import { mapResponseQueuesToGroupedQueue } from "./helpers"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import { useLazyGetQueueExtendedSettingsQuery } from "../../api/controllers/queues"
import usePermissionsCheck from "../../utility/common/usePermissionsCheck"
import { ViewQueues } from "../../permissions"

const tNamespace = "dialogs:transfer."

const DIALOG_TRANSFER_TABS = "dialog-transfer-tabs"

export enum EDialogTransferType {
    Operator = "operator",
    Queue = "queue"
}

export type TDialogTransferModalFormValues = {
    transferType: EDialogTransferType
    transferMessage: string
    selectedOperatorId: string
    queueIdOnOperatorRefused: string
    selectedQueueId: string
}

const initialValues: TDialogTransferModalFormValues = {
    transferType: EDialogTransferType.Queue,
    transferMessage: "",
    queueIdOnOperatorRefused: "",
    selectedQueueId: "",
    selectedOperatorId: ""
}

export interface IDialogTransferModalFormProps {
    onSubmitForm: (formData: TDialogTransferModalFormValues) => void
    channelId?: string
    defaultInitialValues?: TDialogTransferModalFormValues
    onCancel?: () => void
    includeCurrentUser: boolean
    isInDialog?: boolean
    queueId?: string
}

export const DialogTransferModalForm: React.FC<IDialogTransferModalFormProps> = props => {
    const {
        onSubmitForm,
        channelId,
        onCancel,
        includeCurrentUser,
        isInDialog = false,
        queueId,
        defaultInitialValues = {}
    } = props

    const [getAvailableQueues, getAvailableQueuesQuery] = useLazyGetDialogTransferAvailableQueuesQuery()

    const [getAvailableQueuesByOperator, getAvailableQueuesByOperatorQuery] =
        useLazyGetDialogTransferAvailableQueuesByOperatorQuery()

    const selectedDialogId = useAppSelector(selectDialogId) as string
    const { data: dialogData } = useAppSelector(dialogsApi.endpoints.getDialog.select(selectedDialogId))
    const { projectId } = useParams<{ projectId: string }>()

    const hideChoiceQueueIfNotRouted = useAppSelector(selectHideChoiceQueueIfNotRouted)
    const defaultQueueIfNotRouted: string | undefined = useAppSelector(selectDefaultQueueIfNotRouted)

    const internalProjectId = dialogData?.Project.Id ?? projectId

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const { hidePortal } = useActions()

    const handleOnCancelModal = () => {
        onCancel && onCancel()
        hidePortal({
            portalId: DIALOG_TRANSFER_PORTAL_ID
        })
    }

    const channelIdForRequest = (isInDialog && dialogData?.Channel.Id) || channelId

    const handleFormikInstanceChange = useCallback(
        (formData: TDialogTransferModalFormValues) => {
            if (!queueId) return

            const baseQueueParams = {
                projectId: internalProjectId,
                channelId: channelIdForRequest
            }

            if (
                formData.transferType === EDialogTransferType.Operator &&
                formData.selectedOperatorId &&
                !hideChoiceQueueIfNotRouted
            ) {
                getAvailableQueuesByOperator({
                    ...baseQueueParams,
                    operatorId: formData.selectedOperatorId
                })
            }
        },
        [
            internalProjectId,
            getAvailableQueuesByOperator,
            hideChoiceQueueIfNotRouted,
            isInDialog,
            queueId,
            channelIdForRequest
        ]
    )

    const availableQueuesByOperatorOptions = useMemo(
        () => mapResponseQueuesToGroupedQueue(getAvailableQueuesByOperatorQuery.data?.Categories),
        [getAvailableQueuesByOperatorQuery.data?.Categories]
    )

    const values = { ...initialValues, ...defaultInitialValues }

    if (hideChoiceQueueIfNotRouted && defaultQueueIfNotRouted) {
        values.queueIdOnOperatorRefused = defaultQueueIfNotRouted
    }

    enum TabKeys {
        Queue = "queue",
        Operator = "operator"
    }

    const tabKeys = [TabKeys.Queue, TabKeys.Operator].filter<TabKeys>((key): key is TabKeys => !!key)

    const tabEntries = tabKeys.map(key => ({ key, value: t(`${tNamespace}${key}-transfer`) }))

    useEffect(() => {
        if (!queueId || !channelIdForRequest) return
        getAvailableQueues({
            projectId: internalProjectId,
            channelId: channelIdForRequest,
            queueId
        })
    }, [queueId, channelIdForRequest, internalProjectId])

    const availableQueuesOptions = useMemo(
        () => mapResponseQueuesToGroupedQueue(getAvailableQueuesQuery.data?.Categories),
        [getAvailableQueuesQuery.data?.Categories, channelId, queueId]
    )

    const viewClientsSectionAllowed = usePermissionsCheck([ViewQueues])
    const [loadQueueExtendedSettings, { data: queueExtendedSettings }] = useLazyGetQueueExtendedSettingsQuery()

    useEffect(() => {
        if (projectId && queueId && viewClientsSectionAllowed) {
            loadQueueExtendedSettings(
                {
                    projectId,
                    queueId
                },
                true
            )
        }
    }, [projectId, queueId])

    const restrictedQueues = queueExtendedSettings?.RestrictedQueues?.Queues ?? []

    const tree = useAppSelector(selectQueueCategoriesForReroute)
    const filteredTree = useMemo(() => {
        if (!tree || availableQueuesOptions.length === 0) return undefined

        const availableOptions = availableQueuesOptions.flatMap(item => {
            const parentOptionId = [item.value]
            let childrenIds = item.options.map(option => option.value)

            if (restrictedQueues.length) {
                childrenIds = childrenIds.filter(option => !restrictedQueues.includes(option))
            }
            return parentOptionId.concat(childrenIds, ["all"])
        })

        const filteredItems = Object.keys(tree.items)
            .filter(key => availableOptions.includes(key))
            .reduce<Record<ItemId, TreeItem>>((filteredCategories, key: ItemId) => {
                const item = tree.items[key]
                if (item?.children) {
                    filteredCategories[key] = {
                        ...item,
                        children: item?.children.filter(key => availableOptions.includes(key as string)),
                        isExpanded: true
                    }
                } else if (item) {
                    filteredCategories[key] = { ...item }
                }
                return filteredCategories
            }, {})

        return {
            ...tree,
            items: filteredItems
        }
    }, [availableQueuesOptions, restrictedQueues, tree])

    const handleToggleQueuesList = (itemId: ItemId, isExpanded: boolean) => {
        dispatch(
            toggleQueueCategoryForReroute({
                ToggleCategoryId: itemId,
                IsExpanded: isExpanded
            })
        )
    }

    const [selectedCategoryId, setSelectedCategoryId] = useState("")

    const handleQueueTitleClick = (
        categoryItem: TreeItem,
        formikProps: FormikProps<TDialogTransferModalFormValues>
    ) => {
        const { data } = categoryItem
        if (!data) return

        let selectedQueue: typeof data

        if (data.isQueue) {
            selectedQueue = data
        } else if (filteredTree) {
            selectedQueue = Object.values(filteredTree.items).find(
                item => item.data?.parentId === data.Id && item.data?.isQueue && item.data?.isDefault
            )?.data
        }

        if (selectedQueue?.Id) {
            formikProps.setValues(values => ({
                ...values,
                selectedQueueId: selectedQueue.Id
            }))
            setSelectedCategoryId(data.Id)
        }

        return
    }

    return (
        <Formik<TDialogTransferModalFormValues>
            enableReinitialize
            validateOnMount
            initialValues={values}
            validationSchema={validationSchema}
            onSubmit={onSubmitForm}
        >
            {formikProps => {
                return (
                    <Form
                        className={styles.dialogTransferModal__form}
                        onSubmit={formikProps.handleSubmit}
                        onKeyPress={preventSubmitOnEnter}
                        role="form"
                    >
                        <Tabs
                            id={DIALOG_TRANSFER_TABS}
                            alwaysReload={false}
                            entries={tabEntries}
                            onSelect={key => formikProps.setFieldValue("transferType", key, false)}
                        >
                            <Tab.Pane eventKey={TabKeys.Queue}>
                                {filteredTree && (
                                    <div className={styles.dialogTransferModal__tree}>
                                        <LazyCatalog
                                            tree={filteredTree}
                                            onToggle={handleToggleQueuesList}
                                            onRootItemTitleClick={() => dispatch(viewAllQueues())}
                                            onTitleClick={(categoryItem: TreeItem) =>
                                                handleQueueTitleClick(categoryItem, formikProps)
                                            }
                                            editingId={formikProps.values.selectedQueueId}
                                            onCanEdit={() => false}
                                            categoryIcon={handleIcon}
                                            selectedCategoryId={selectedCategoryId}
                                        />
                                    </div>
                                )}
                            </Tab.Pane>
                            <Tab.Pane eventKey={TabKeys.Operator}>
                                <div className={styles.dialogTransferModal__operatorsContainer}>
                                    <DebouncedValidatableInput
                                        name="transferMessage"
                                        className={cn(
                                            styles.dialogTransferModal__formRow_double,
                                            styles.dialogTransferModal__textarea
                                        )}
                                        id="transfer-message-textarea"
                                        as="textarea"
                                        label={t(`${tNamespace}transfer-message`)}
                                        placeholder={t(`${tNamespace}input-text`)}
                                    />
                                    {!hideChoiceQueueIfNotRouted && (
                                        <div
                                            data-testid={testId.queueIdOnOperatorRefused}
                                            className={styles.dialogTransferModal__formRow_double}
                                        >
                                            <CreatableGroupedTagSelect
                                                name="queueIdOnOperatorRefused"
                                                id="queue-id-on-operator-refused"
                                                placeholder={t(`${tNamespace}choose-queue`)}
                                                label={t(`${tNamespace}queue-on-operator-refused`)}
                                                options={availableQueuesByOperatorOptions}
                                                disabled={availableQueuesByOperatorOptions.length === 0}
                                                asSelector
                                                t={t}
                                            />
                                        </div>
                                    )}
                                    <DialogTransferOperatorSearch
                                        name="selectedOperatorId"
                                        className={styles.dialogTransferModal__formRow_double}
                                        disabledInlineErrors
                                        includeCurrentUser={includeCurrentUser}
                                        channelId={channelIdForRequest}
                                        queueId={queueId}
                                    />
                                </div>
                            </Tab.Pane>
                        </Tabs>
                        <div
                            className={cn(
                                isInDialog
                                    ? styles.dialogTransferModal__actions
                                    : styles.dialogTransferModal__actions_centered
                            )}
                        >
                            {isInDialog && (
                                <LoadingButton as="div" variant="light" onClick={handleOnCancelModal}>
                                    {t(`${tNamespace}cancel`)}
                                </LoadingButton>
                            )}
                            <TooltipTrigger
                                id="transfer-submit-btn-tooltip"
                                placement="top"
                                content={Object.values(formikProps.errors)
                                    .map(err => t(`${err}`))
                                    .join("\n")}
                                condition={Boolean(Object.keys(formikProps.errors).length)}
                                delay={{ hide: 0, show: 450 }}
                            >
                                <LoadingButton
                                    as="button"
                                    variant="primary"
                                    type="submit"
                                    disabled={Boolean(Object.keys(formikProps.errors).length)}
                                    testId={testId.dialogTransferModalSubmit}
                                    className={!isInDialog ? styles.dialogTransferModal__btn_extended : undefined}
                                >
                                    {t(`${tNamespace}transfer`)}
                                </LoadingButton>
                            </TooltipTrigger>
                        </div>
                        <FormikFormObserver
                            onValuesChange={handleFormikInstanceChange}
                            observeFields={[
                                "transferType",
                                "selectedOperatorId",
                                "selectedQueueId",
                                "queueIdOnOperatorRefused"
                            ]}
                        />
                    </Form>
                )
            }}
        </Formik>
    )
}
