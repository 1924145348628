import React from "react"
import cn from "classnames"
import styles from "../MessageBasic/MessageBasic.module.scss"
import { MessageTextType } from "../../../../../models/Dialogs/message"
import DialogContentEditor, { EditorPlacement } from "../../../DialogContentEditor/DialogContentEditor"
import unescape from "lodash/unescape"
import Linkify from "linkify-react"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { textDomParser } from "../../../../../utility/common/textDomParser"
import useReplaceEmojisShortcodes from "../../../../../utility/common/useReplaceEmojiShortcodes"
import { STARTER_MESSAGE } from "../../../../../utility/dialogs/constant"
import { formatLinksInMarkdown } from "./helpers"
import { removeAngleBracketsFromLinks } from "../../../DialogMessageInput/helpers"

export interface MessageTextProps {
    text: string
    isDeleteMod?: boolean
    type: MessageTextType
    hasButtonsSection: boolean
    isWhiteFont?: boolean
}

const tNamespace = "dialogs:"

const MessageText: React.FC<MessageTextProps> = props => {
    const { text, isDeleteMod, type, hasButtonsSection, isWhiteFont } = props
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const textWithCorrectEmojis = useReplaceEmojisShortcodes(text)

    const renderMessageInner = (isDeleted: boolean) => {
        if (text === STARTER_MESSAGE) {
            return t(`${tNamespace}list.starter-message`)
        }

        if (isDeleted) {
            return (
                <div
                    className={cn(
                        styles.messageBasic__deletedText,
                        isWhiteFont && styles.messageBasic__deletedText_whiteFont
                    )}
                >
                    {t(`${tNamespace}info.deleted-text-placeholder`)}
                </div>
            )
        }

        switch (type) {
            case MessageTextType.Markdown:
                const messageWithCorrectLinks = removeAngleBracketsFromLinks(text)
                const formattedMessageText = formatLinksInMarkdown(unescape(messageWithCorrectLinks))

                return (
                    <DialogContentEditor
                        content={formattedMessageText}
                        placementType={EditorPlacement.message}
                        className={cn(
                            styles.messageBasic__markdownContainer,
                            hasButtonsSection && styles.messageBasic__markdownContainer_withButtons
                        )}
                        t={t}
                        dispatch={dispatch}
                    />
                )
        }

        const parsedMessage = textDomParser(unescape(textWithCorrectEmojis))

        return <Linkify options={{ target: "_blank" }}>{parsedMessage}</Linkify>
    }

    return (
        <div className={cn(styles.messageBasic__text, isDeleteMod && styles.messageBasic__deletedText)}>
            {renderMessageInner(Boolean(isDeleteMod))}
        </div>
    )
}

export default MessageText
