import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useRouteMatch } from "react-router-dom"
import { removeNewslineItem } from "../../../../api/controllers/knowledgeBase/helpers"
import { NewslineItem as NewslineItemType } from "../../../../models/knowledgeBase/newsline"
import { useAppSelector } from "../../../../store/hooks"
import { selectCurrentProjectId } from "../../../../store/projects/selectors"
import styles from "./NewslineItem.module.scss"

export interface NewslineItemProps {
    item: NewslineItemType
}

export const NewslineItem = ({ item }: NewslineItemProps) => {
    const dispatch = useDispatch()
    const projectId = useAppSelector(selectCurrentProjectId)
    const articlePath = `/project/${projectId}/knowledge-base/article/view/${item.ArticleCode}`
    const isArticleOpened = Boolean(useRouteMatch(articlePath))

    useEffect(() => {
        if (isArticleOpened && item.IsHideAfterReading && projectId) {
            dispatch(removeNewslineItem(item.Id, projectId))
        }
    }, [isArticleOpened])

    return (
        <li className={styles.item}>
            <span className={styles.item__title}>{item.Title}</span>
        </li>
    )
}
