import React from "react"
import { Message, MessageType } from "../../models/Dialogs/message"
import styles from "./OutdatedMessageList.module.scss"
import OutdatedMessageItem from "../OutdatedMessageItem/OutdatedMessageItem"
import { mapToArray } from "../../helpers/array"

export interface OutdatedMessageListProps {
    messages: Map<string, Message>
    reverseInOut?: boolean
}

const OutdatedMessageList: React.FC<OutdatedMessageListProps> = props => {
    const { messages, reverseInOut } = props
    const messagesArray = mapToArray<string, Message>(messages)

    const renderMessage = (message: Message) => {
        switch (message.MsgType) {
            case MessageType.DialogStart:
                return null
            default:
                return (
                    <OutdatedMessageItem
                        key={message.MsgId}
                        className={styles.messageList__item}
                        message={message}
                        reverseInOut={reverseInOut}
                    />
                )
        }
    }

    return <div className={styles.messageList}>{messagesArray.map(message => renderMessage(message))}</div>
}

export default OutdatedMessageList
