import React, { memo, useCallback, useEffect, useState } from "react"
import styles from "./GlobalUsersTable.module.scss"
import { useTranslation } from "react-i18next"
import { GlobalUser } from "../../../models/globalUser"
import { CellProps, Column } from "react-table"
import SettingsButton from "../../SettingsButton/SettingsButton"
import UserCard from "../../UserCard/UserCard"
import { formTranslation } from "../../../locales/form"
import { faPen } from "@fortawesome/pro-light-svg-icons/faPen"
import SettingsContextMenu from "../../SettingsContextMenu/SettingsContextMenu"
import SettingsContextMenuItem from "../../SettingsContextMenuItem/SettingsContextMenuItem"
import { GlobalUserSurvey } from "../../../models/globalUserSurvey"
import SettingsTableWithPagination from "../../SettingsTable/SettingsTableWithPagination/SettingsTableWithPagination"
import { ITEMS_PER_PAGE, PaginationRequest, PaginationResponse } from "../../../models/pagination"
import SearchInput from "../../SearchInput/SearchInput"
import { testId } from "../../../utility/tests/testId"
import { GLOBAL_USERS_TABLE_CONST } from "./GlobalUsersTable.const"

export type GlobalUsersTableProps = {
    users: PaginationResponse<GlobalUser>
    onSettingsClick: (user: GlobalUser) => void
    globalUserSurvey: GlobalUserSurvey
    allUsersQueryTriggerCallback: (args: PaginationRequest) => void
}

const GlobalUsersTable = (props: GlobalUsersTableProps) => {
    const { users, onSettingsClick, allUsersQueryTriggerCallback } = props
    const { t } = useTranslation()
    const { tNamespace, tPaths } = GLOBAL_USERS_TABLE_CONST

    const [currentPageIndex, setCurrentPageIndex] = useState(0)
    const [searchValue, setSearchValue] = useState("")

    const handlePageChange = useCallback((pageIndex: number, searchValue = "") => {
        setCurrentPageIndex(pageIndex)
        setSearchValue(searchValue)
    }, [])

    useEffect(() => {
        allUsersQueryTriggerCallback({
            PageFrom: currentPageIndex,
            PageSize: ITEMS_PER_PAGE,
            Search: searchValue
        })
    }, [searchValue, currentPageIndex])

    const getItems = (role: GlobalUser) => {
        return (
            <SettingsContextMenuItem
                id="global-users-edit"
                icon={faPen}
                text={t(formTranslation.edit)}
                onClick={() => onSettingsClick(role)}
            />
        )
    }

    const columns: Array<Column<GlobalUser>> = [
        {
            Header: t(formTranslation.user).toString(),
            id: "User",
            accessor: original => original.FirstName + " " + original.LastName,
            Cell: ({ row: { original } }: CellProps<GlobalUser>) => (
                <UserCard firstName={original.FirstName} lastName={original.LastName} picture={original.Picture} />
            )
        },
        {
            Header: t(formTranslation.login).toString(),
            accessor: "Login"
        },
        {
            id: "Settings",
            disableSortBy: true,
            Cell: (props: CellProps<GlobalUser>) => (
                <SettingsContextMenu items={getItems(props.row.original)}>
                    <SettingsButton />
                </SettingsContextMenu>
            )
        }
    ]

    return (
        <>
            <SearchInput
                onChange={searchValue => handlePageChange(0, searchValue)}
                dataTestId={testId.allUsersTableSearch}
                delay={500}
            />
            {users.Items.length > 0 ? (
                <SettingsTableWithPagination<GlobalUser>
                    columns={columns}
                    data={users?.Items ?? []}
                    recordsTotal={users?.ItemsTotal ?? 0}
                    pageSize={ITEMS_PER_PAGE}
                    pageIndex={currentPageIndex}
                    onPageIndexChange={handlePageChange}
                    onRowClick={onSettingsClick}
                    hideSearch
                />
            ) : (
                <div className={styles.table_empty}>{t(`${tNamespace}:${tPaths.noUsers}`)}</div>
            )}
        </>
    )
}

export default memo(GlobalUsersTable)
