import { ParameterDeclaration, ParameterType, ParamValues } from "../../models/parameterDeclaration"
import { logError } from "./logError"

// TODO Сделать typeguard для всех типов параметров агентов в настройках
// eslint-disable-next-line
export const parseField = (value: string, defaultValue?: any, logged?: boolean): any => {
    if (value === "") {
        return defaultValue ?? value
    }

    try {
        return JSON.parse(value)
    } catch (e) {
        logged && logError(e)
        return defaultValue ?? value
    }
}

export const parseFieldWithArrayCheck = (
    value: string,
    type: ParameterType,
    defaultValue: string,
    logged?: boolean
): unknown => {
    try {
        const parsedVal = JSON.parse(value)
        if (type === ParameterType.Boolean && Array.isArray(parsedVal)) {
            return JSON.parse(defaultValue)
        }
        return parsedVal
    } catch (e) {
        logged && logError(e)
        return defaultValue ?? value
    }
}

export const getDefaultParams = (declarations: ParameterDeclaration[]): ParamValues =>
    declarations.reduce((params: ParamValues, declaration) => {
        params[declaration.name] = declaration.default_value
        return params
    }, {})
