export const SUB_ARTICLES_LOCALES = {
    tNamespace: "knowledgeBase",
    tPath: {
        subArticles: "sub-articles"
    }
}

export const testId = {
    subArticleItem: "sub-article-item"
}

export const SUB_ARTICLES_LIST_MAX_LENGTH = 3
