import { format, isSameDay, startOfDay } from "date-fns"
import { getDateLocale } from "../../../../../locales"
import { getLanguage } from "../../../../../utility/common/language"
import { useTranslation } from "react-i18next"
import styles from "./RerouteTime.module.scss"

const tNamespace = "dialogs"

export const RerouteTime = ({ timestamp }: { timestamp: number }) => {
    const { i18n } = useTranslation(tNamespace)
    const locale = { locale: getDateLocale(getLanguage(i18n)) }
    const isDifferentDay = !isSameDay(startOfDay(new Date()), startOfDay(new Date(timestamp)))
    const timeFormat = isDifferentDay ? "dd.MM.yyyy HH:mm" : "HH:mm"

    return <span className={styles.text}>{format(new Date(timestamp), timeFormat, locale)}</span>
}
