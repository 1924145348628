import { DialogBadge, DialogPerson, IGetOperatorClientBadgesResponse } from "../../models/Dialogs/dialog"
import { ApiMessage, MessageDirection } from "../../models/Dialogs/message"
import store from "../../store/store"
import { dialogsApi, DialogsApiEndpoints, updateDialogBadges } from "./dialogs"
import { PatchCollection } from "@reduxjs/toolkit/dist/query/core/buildThunks"
import { apiCallExceptionToSystemError } from "../../utility/common/apiCallExceptionToSystemError"
import {
    saveNotificationError,
    saveNotificationFakeError,
    saveNotificationInfo
} from "../../store/notifications/thunks"
import { Dispatch } from "../../utility/common/storeHelper"
import { NotificationInternalPayload } from "../../models/notification"

export const refetchDialogBadges = () => {
    return dialogsApi.endpoints.getDialogBadges.initiate(undefined, {
        forceRefetch: true
    })
}

export const refetchActiveDialogsCount = () => {
    return dialogsApi.endpoints.getActiveDialogsCount.initiate(undefined, {
        forceRefetch: true
    })
}

export const upsertDialogBadges = (newData: DialogBadge[]) => {
    return dialogsApi.util.upsertQueryData(DialogsApiEndpoints.getDialogBadges, undefined, newData)
}

export const updateOperatorClientsBadges = (client: DialogPerson, projectId: string) => {
    return dialogsApi.util.updateQueryData(DialogsApiEndpoints.getOperatorClientsBadges, undefined, oldState => {
        const clientEntry: IGetOperatorClientBadgesResponse = {
            OmniUserId: client.OmniUserId,
            Firstname: client.Firstname,
            Lastname: client.Lastname,
            Middlename: client.Middlename,
            Picture: client.Picture,
            ProjectId: projectId
        }

        const oldStateFiltered = oldState.filter(operatorClient => operatorClient.OmniUserId !== client.OmniUserId)

        const newState = [clientEntry, ...oldStateFiltered]
        Object.assign(oldState, newState)
    })
}

export const updateCurrentDialogBadgeWithOutgoing = (dialogId: string) => {
    return updateDialogBadges(oldState => {
        const idxIntoList = oldState.findIndex(x => x.Id === dialogId)

        if (idxIntoList > -1) {
            oldState[idxIntoList] = {
                ...oldState[idxIntoList],
                UnreadMessages: 0,
                IsReplied: true,
                Stat: {
                    ...oldState[idxIntoList].Stat,
                    LastOutgoingMessageSent: Date.now(),
                    LastIncomingMessageSent: 0
                }
            }
        }
    })
}

export const updateDialogBadge = (badgeIndex: number, badge: DialogBadge, message: ApiMessage) => {
    const tDialogsBadgesDescription = "dialogs:dialogs-list.description.attachment"

    return updateDialogBadges(oldState => {
        const updatedBadge = {
            ...badge,
            UnreadMessages: badge.UnreadMessages + 1
        }

        const newPreview = message.Fields.Text
        const newAttachments = message.Fields.Attachments

        if (newPreview) {
            updatedBadge.Preview = newPreview
        } else if (newAttachments.length) {
            updatedBadge.Preview = tDialogsBadgesDescription
        }

        if (message.Fields.Direction === MessageDirection.Incoming) {
            updatedBadge.Stat = {
                ...badge.Stat,
                LastOutgoingMessageSent: 0,
                LastIncomingMessageSent: Date.now()
            }
        }

        if (message.Fields.Direction === MessageDirection.Outgoing) {
            updatedBadge.Stat = {
                ...badge.Stat,
                LastOutgoingMessageSent: Date.now(),
                LastIncomingMessageSent: 0
            }
        }

        oldState[badgeIndex] = updatedBadge
    })
}

export const selectDialogBadges = () => {
    const dialogBadgesSelector = dialogsApi.endpoints.getDialogBadges.select()
    const { data: dialogBadges } = dialogBadgesSelector(store.getState())
    return dialogBadges
}

export const handleQueryApiError = (
    dispatch: Dispatch,
    errorMessage: string,
    e: unknown,
    patchResults?: (PatchCollection | undefined)[]
) => {
    const error = apiCallExceptionToSystemError(errorMessage, e)
    saveNotificationError(dispatch, error)

    if (patchResults) {
        for (const patchResult of patchResults) {
            if (patchResult) patchResult.undo()
        }
    }
}

export const handleQueryApiInfo = (
    dispatch: Dispatch,
    message: NotificationInternalPayload,
    patchResults?: (PatchCollection | undefined)[]
) => {
    saveNotificationInfo(dispatch, message)

    if (patchResults) {
        for (const patchResult of patchResults) {
            if (patchResult) patchResult.undo()
        }
    }
}

export const handleQueryApiFakeError = (
    dispatch: Dispatch,
    message: NotificationInternalPayload,
    patchResults?: (PatchCollection | undefined)[]
) => {
    saveNotificationFakeError(dispatch, message)

    if (patchResults) {
        for (const patchResult of patchResults) {
            if (patchResult) patchResult.undo()
        }
    }
}
