import { getCookie } from "../auth/credentialHelper"
import { tryExtractProjectIdFromPath } from "./tryExtractProjectIdFromPath"
import { usersController } from "../../api/controllers/users"

export const changeProjectIfNeeded = async () => {
    const projectIdFromCookie = getCookie("CurrentCustomerId")
    const projectIdFromPath = tryExtractProjectIdFromPath()

    if (projectIdFromCookie && projectIdFromPath) {
        if (projectIdFromPath !== projectIdFromCookie) {
            await usersController.changeProject(projectIdFromPath)
        }
    }
}
