const TRANSLATION_NAMESPACE = "error:users."
const NOTIFICATION_TRANSLATION_NAMESPACE = "notification:"

export const GET_CURRENT_USER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-current-user-failed`
export const GET_USERS_BY_PROJECT_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-users-by-project-failed`
export const GET_USER_EXTEND_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-user-extend-failed`
export const GET_USERS_PAGE_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}fetch-user-page-failed`
export const GET_ALL_USERS_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-all-users-failed`
export const UPDATE_USER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-user-failed`
export const UPDATE_PROJECT_USER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-project-user`
export const UPDATE_AVATAR_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-avatar-failed`
export const UPDATE_GLOBAL_USER_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}update-global-user`
export const GET_REMOTE_WORKPLACE_AUTH_TOKEN_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-remote-workplace-auth-token`
export const REFRESH_REMOTE_WORKPLACE_AUTH_TOKEN_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}refresh-remote-workplace-auth-token`
export const GET_CLIENT_TYPES_FAILED_MESSAGE = `${TRANSLATION_NAMESPACE}get-client-types-failed`
export const CHANGES_SAVED = `${NOTIFICATION_TRANSLATION_NAMESPACE}changes-saved`
export const PROFILE_INFO_UPDATED = `${NOTIFICATION_TRANSLATION_NAMESPACE}user-profile:profile-info-updated`
