import { QueryDefinition, QueryStatus } from "@reduxjs/toolkit/query"
import { AsyncDataProps, AsyncErrorProps } from "../../utility/common/asyncHelper"
import { BaseQueryError, BaseQueryFn } from "@reduxjs/toolkit/src/query/baseQueryTypes"
import { SerializedError } from "@reduxjs/toolkit"

// Redux Toolkit prohibits import an original UseQueryStateDefaultResult. Therefore, I just made own
// eslint-disable-next-line
type UseQueryStateBaseResult<D extends QueryDefinition<any, any, any, any>, R> = {
    /**
     * Where `data` tries to hold data as much as possible, also re-using
     * data from the last arguments passed into the hook, this property
     * will always contain the received data from the query, for the current query arguments.
     */
    currentData?: R
    /**
     * Query has not started yet.
     */
    isUninitialized: boolean
    /**
     * Query is currently loading for the first time. No data yet.
     */
    isLoading: boolean
    /**
     * Query is currently fetching, but might have data from an earlier request.
     */
    isFetching: boolean
    /**
     * Query has data from a successful load.
     */
    isSuccess: boolean
    /**
     * Query is currently in "error" state.
     */
    isError: boolean
    status: QueryStatus
    data?: R
    /**
     * The received error if applicable
     */
    error?:
        | SerializedError
        // Redux Toolkit prohibits import an original UseQueryStateDefaultResult. Therefore, I just made own
        // eslint-disable-next-line
        | (D extends QueryDefinition<any, infer BaseQuery, any, any> ? BaseQueryError<BaseQuery> : never)
    endpointName?: string
    /**
     * Time that the latest query started
     */
    startedTimeStamp?: number
    /**
     * Time that the latest query was fulfilled
     */
    fulfilledTimeStamp?: number
}

export type TGetQueryResult<
    T extends UseQueryStateBaseResult<QueryDefinition<QueryArg, BaseQueryFn, string, ResultType, string>, unknown>,
    QueryArg = string,
    ResultType = Exclude<T["data"], undefined>
> = ResultType

export interface AsyncQueryProps<
    QueryArg,
    ResultType,
    T = Array<UseQueryStateBaseResult<QueryDefinition<QueryArg, BaseQueryFn, string, ResultType, string>, unknown>>
> {
    queries: T
    processView: JSX.Element
    errorView: (props: AsyncErrorProps) => JSX.Element
    defaultView: JSX.Element
    children: (props: AsyncDataProps<unknown[]>) => JSX.Element
}

function AsyncQueries<QueryArg, ResultType>(props: AsyncQueryProps<QueryArg, ResultType>) {
    const { queries, processView, children, errorView, defaultView } = props
    const data = queries.map(q => q.data).filter((item): item is ResultType => !!item)

    const isUninitialized = queries.some(q => q.isUninitialized)
    const isSomeUndefined = queries.some(q => q.data === undefined)
    const isLoading = queries.some(q => q.isLoading)
    const isError = queries.some(q => q.isError)
    const error = queries.map(q => q.error)

    const isSuccess = queries.every(q => q.isSuccess)

    if (isSomeUndefined) {
        return defaultView
    }

    if (isUninitialized) {
        return defaultView
    }

    if (isLoading) {
        return processView
    }

    if (isError && error.length) {
        return errorView({ message: "Error occurred" })
    }

    if (isSuccess && data) {
        return children({ data })
    }

    return null
}

export default AsyncQueries
