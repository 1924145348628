import { faRedo } from "@fortawesome/pro-light-svg-icons/faRedo"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import IconButton from "../../../../../../components/IconButton/IconButton"
import { SendingJob } from "../../../../../../models/massMailing"
import { testId } from "../../../../../../utility/tests/testId"
import styles from "./MassMailingViewInfo.module.scss"

const tNamespace = "massMailing:"
const tStatisticNamespace = `${tNamespace}statistic.`

export interface MassMailingViewInfoProps {
    className: string
    mailing: SendingJob
    isItemsLoadAllowed: boolean
    loadItems: () => void
}

export const MassMailingStatisticKeys: Record<keyof SendingJob["Statistic"], string> = {
    CountItems: "total",
    ErrorItems: "error",
    SentItems: "sent",
    DeliveredItems: "delivered"
}

export const MassMailingViewInfo = (props: MassMailingViewInfoProps) => {
    const { className, mailing, isItemsLoadAllowed, loadItems } = props
    const { t } = useTranslation()
    const fileName = t(`${tNamespace}file-name`, { title: mailing.Data.Title.replaceAll(" ", "_") }) + ".csv"

    return (
        <div className={cn(className, styles.info)}>
            {mailing.Data.CsvFileLink && (
                <a
                    href={mailing.Data.CsvFileLink + `?file_name=${fileName}`}
                    target="_blank"
                    rel="noreferrer"
                    className={styles.info__fileLink}
                    data-testid={testId.downloadMassMailingFile}
                >
                    {fileName}
                </a>
            )}
            <div className={styles.info__statistic}>
                <ul className={styles.info__list}>
                    {Object.entries(mailing.Statistic).map(([key, value]) => {
                        const tKey = MassMailingStatisticKeys[key as keyof SendingJob["Statistic"]]
                        const tValue = t(`${tStatisticNamespace}${tKey}`)
                        return <li key={key}>{`${tValue}: ${value}`}</li>
                    })}
                </ul>
                <IconButton
                    hidden={!isItemsLoadAllowed}
                    icon={faRedo}
                    className={styles.info__loadBtn}
                    onClick={loadItems}
                    variant="outline-primary"
                    testId={testId.reloadMassMailingItems}
                />
            </div>
        </div>
    )
}
