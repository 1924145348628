import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { dialogsApi, useSendIntentMutation } from "../../../api/controllers/dialogs"
import { OperatorStatusValue } from "../../../models/operatorStatus"
import { FilledSlot, FillingSlot } from "../../../models/slot"
import { addToMessageInputText, closeKnowledgeBaseSidebar } from "../../../store/dialogs/actions"
import {
    selectCreateDialogOutgoingData,
    selectCurrentChannel,
    selectDialogEmailMessageMeta,
    selectDialogEmailMessageSlots,
    selectDialogId,
    selectCurrentClientOmniUserId
} from "../../../store/dialogs/selectors"
import { selectCurrentAnswer } from "../../../store/knowledgeBase/selectors"
import { selectCurrentProjectId } from "../../../store/projects/selectors"
import { selectSlots } from "../../../store/slots/selectors"
import { useAppSelector } from "../../../store/hooks"
import { selectOperatorStatusState } from "../../../store/userOperator/selectors"
import { fillSlots, getFilledSlots } from "../../../utility/common/fillSlots"
import { ArticleActionButtonsProps } from "../ArticleActionButtons"

export interface ArticleActionButtonsActions {
    edit?: {
        disabled: boolean
        onClick: () => void
    }
    send?: {
        disabled: boolean
        onClick: () => void
    }
}

export const useDefaultActions = ({
    symbolCode,
    isPublished,
    setSlotsToFillState,
    slotsToFill
}: ArticleActionButtonsProps): ArticleActionButtonsActions => {
    const dispatch = useDispatch()

    const [sendIntentMutation] = useSendIntentMutation()
    const projectId = useAppSelector(selectCurrentProjectId) ?? ""

    const selectedDialogId = useAppSelector(selectDialogId)
    const strictDialogId = selectedDialogId ?? ""
    const { data: dialogData } = useAppSelector(dialogsApi.endpoints.getDialog.select(strictDialogId))

    const currentClientOmniUserId = useAppSelector(selectCurrentClientOmniUserId) ?? ""
    const { data: clientData } = useAppSelector(
        dialogsApi.endpoints.getOperatorClient.select({ projectId, omniUserId: currentClientOmniUserId })
    )

    const currentAnswer = useAppSelector(selectCurrentAnswer)
    const slots = useAppSelector(selectSlots)
    const dialogCreateOutgoingData = useAppSelector(selectCreateDialogOutgoingData)
    const currentChannel = useAppSelector(selectCurrentChannel)

    const [isNeedToFillOrEdit, setIsNeedToFillOrEdit] = useState(true)

    const operatorStatusState = useAppSelector(selectOperatorStatusState)
    const isOperatorOffline = operatorStatusState.data?.Value === OperatorStatusValue.Offline

    const handleFillSlots = (slotIds: string[], filledSlots: FilledSlot[]) => {
        const slotsToFill: FillingSlot[] = []
        for (const slotId of slotIds) {
            const slot = slots.find(slot => slot.ExternalId === slotId)

            if (slot) {
                slotsToFill.push({
                    Id: slotId,
                    Title: slot.Title ?? slotId,
                    Value: filledSlots.find(slot => slot.Id === slotId)?.Value
                })
            }
        }

        if (setSlotsToFillState) {
            setSlotsToFillState({
                selectedDialogId: selectedDialogId ?? "",
                selectedClientId: currentClientOmniUserId,
                slotsToFill
            })
        }
    }

    const isHandleFillingSlot = (slotsInArticle: string[]): boolean => {
        const filledSlots = (dialogData ?? clientData)?.SlotContext.FilledSlots ?? []

        if (slotsInArticle && slots && isNeedToFillOrEdit) {
            handleFillSlots(slotsInArticle, filledSlots)
            setIsNeedToFillOrEdit(false)
            return true
        }

        return false
    }

    const getSlotsInArticle = (text: string): string[] => {
        return Array.from(new Set(text.match(/{{[a-zA-Z0-9_-]*}}/g)?.map(slot => slot.slice(2, -2))))
    }

    const emailMeta = useAppSelector(selectDialogEmailMessageMeta)
    const emailSlots = useAppSelector(selectDialogEmailMessageSlots)

    const mergeEmailSlots = (slotsToFill: FillingSlot[], emailSlots: Record<string, string>): FillingSlot[] => {
        const emailSlotArray = Object.entries(emailSlots).map(([key, value]) => ({
            Id: key,
            Value: value || ""
        }))

        return [...slotsToFill, ...emailSlotArray]
    }

    const handleSendArticle = useCallback(() => {
        if (projectId) {
            const articleText = currentAnswer?.Text ?? ""
            const slotsInArticle = getSlotsInArticle(articleText)
            if (slotsInArticle.length && isHandleFillingSlot(slotsInArticle)) {
                return
            }

            if (dialogData && dialogData.Channel) {
                sendIntentMutation({
                    OmniUserId: dialogData.Client.OmniUserId,
                    ChannelId: dialogData.Channel.Id,
                    StartNewDialog: false,
                    IntentId: symbolCode,
                    // TODO: handle this field later
                    DoDetachOperator: false,
                    ProjectId: dialogData.Project.Id,
                    Meta: emailMeta || {}
                })
            } else if (clientData && currentChannel) {
                const slots = mergeEmailSlots(slotsToFill, emailSlots)
                sendIntentMutation({
                    OmniUserId: clientData.Id,
                    ChannelId: currentChannel.Id,
                    StartNewDialog: true,
                    IntentId: symbolCode,
                    // TODO: handle this field later
                    DoDetachOperator: false,
                    ProjectId: clientData.Project.Id,
                    Meta: emailMeta || {},
                    UpdatedSlots: slots.length ? slots.map(slot => ({ Id: slot.Id, Value: slot.Value ?? "" })) : [],
                    ChannelUserId: currentChannel?.ChannelUserId
                })
                setSlotsToFillState &&
                    setSlotsToFillState({ selectedClientId: "", selectedDialogId: "", slotsToFill: [] })
            } else if (dialogCreateOutgoingData) {
                sendIntentMutation({
                    ChannelId: dialogCreateOutgoingData.channel.Id,
                    ChannelUserId: dialogCreateOutgoingData.channelUserId,
                    StartNewDialog: true,
                    IntentId: symbolCode,
                    // TODO: handle this field later
                    DoDetachOperator: false,
                    ProjectId: projectId
                })
            }

            dispatch(closeKnowledgeBaseSidebar())
        }
    }, [
        dialogData,
        symbolCode,
        projectId,
        dispatch,
        sendIntentMutation,
        currentAnswer,
        isNeedToFillOrEdit,
        clientData,
        slotsToFill
    ])

    const handleEditArticle = useCallback(() => {
        if (selectedDialogId || currentClientOmniUserId) {
            const articleText = currentAnswer?.Text ?? ""
            let formattedText
            const slotsInArticle = getSlotsInArticle(articleText)
            if (slotsInArticle.length) {
                if (isHandleFillingSlot(slotsInArticle)) {
                    return
                }

                const filledSlots = dialogData
                    ? getFilledSlots(dialogData)
                    : slotsToFill.map(slot => ({ Id: slot.Id, Value: slot.Value ?? "" })) ?? []

                formattedText = fillSlots(articleText, dialogData?.Operator, filledSlots)
            } else {
                console.warn("Error with get dialog data, full text was pasted")
                formattedText = articleText
            }

            dispatch(addToMessageInputText(formattedText))
            dispatch(closeKnowledgeBaseSidebar())
        }
    }, [
        currentAnswer,
        selectedDialogId,
        dispatch,
        dialogData,
        currentClientOmniUserId,
        isNeedToFillOrEdit,
        slotsToFill
    ])

    return {
        edit: {
            disabled: false,
            onClick: handleEditArticle
        },
        send: {
            disabled: !isPublished || isOperatorOffline,
            onClick: handleSendArticle
        }
    }
}
