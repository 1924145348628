import i18n from "i18next"

const tPath = {
    sendMessageError: "dialogs:error.send-message-error",
    hasActiveDialogWithOperator: "dialogs:notifications.has-active-dialog-with-operator",
    hasActiveDialogWithBot: "dialogs:notifications.has-active-dialog-with-bot"
}

export const REQUEST_STATUS = "Request status: "

export const HAS_ACTIVE_DIALOG_WITH_OPERATOR = {
    Title: {
        Value: i18n.t(tPath.sendMessageError),
        NeedLocalization: false
    },
    Description: {
        Value: i18n.t(tPath.hasActiveDialogWithOperator),
        NeedLocalization: false
    }
}

export const HAS_ACTIVE_DIALOG_WITH_BOT = {
    Title: {
        Value: i18n.t(tPath.sendMessageError),
        NeedLocalization: false
    },
    Description: {
        Value: i18n.t(tPath.hasActiveDialogWithBot),
        NeedLocalization: false
    }
}
