import { ActionResult, Dispatch } from "../../utility/common/storeHelper"
import configController from "../../api/controllers/config"
import { errorTranslation } from "../../locales/error"
import { actions } from "./slice"
import { handleHttpException } from "../handleHttpException"
import { changeProjectIfNeeded } from "../../utility/project/changeProjectIfNeeded"
// import { THEME_SETTINGS_FILE_DEFAULT } from "../../constants"
// import { fetchPublicFile } from "../../helpers/file"

export const startApp = (): ActionResult<Promise<void>> => async (dispatch: Dispatch) => {
    try {
        dispatch(actions.getConfigProcess())

        // Users can open a link that references another project.
        await changeProjectIfNeeded()

        const appConfig = await configController.get()

        // Enable it for debug theming
        // Local data is in public/themeSettings.json
        // appConfig.WebConfig.themeSettings = await fetchPublicFile(THEME_SETTINGS_FILE_DEFAULT)

        if (appConfig.WebConfig.themeSettings.fontPath) {
            appConfig.WebConfig.themeSettings.fontPath =
                process.env.PUBLIC_URL + appConfig.WebConfig.themeSettings.fontPath
        }

        dispatch(actions.getConfigSuccess(appConfig))
    } catch (e) {
        handleHttpException(e, errorTranslation.config, err => actions.getConfigFailed(err), dispatch)
    }
}
