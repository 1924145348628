import { FormEvent, MutableRefObject, useCallback, useEffect, useRef, useState } from "react"
import { useModal } from "../common/useModal"
import AlertDialog from "../../components/AlertDialog/AlertDialog"
import { useTranslation } from "react-i18next"
import { getIn } from "formik"
import { DurationSettingsValues, TimeoutSettingsValues } from "../../models/projectSettingsValues"
import { Time } from "../common/time"
import { isTimeoutSettings, TimeoutSettings } from "../../models/projectSettings"
import debounce from "lodash/debounce"

const tProjectNamespace = "project:"
const wait = 300

const durationSettingsNames: (keyof DurationSettingsValues)[] = ["Days", "Hours", "Minutes", "Seconds"]

export function undefinedSettingsCount<T>(
    changedFields: MutableRefObject<Set<string>>,
    initializedFields: MutableRefObject<Set<string>>,
    name: string,
    defaultSettings: T,
    settings?: T
) {
    if (typeof defaultSettings !== "object" || isTimeoutSettings(defaultSettings)) {
        if (typeof settings === "undefined" && typeof defaultSettings !== "string") {
            initializedFields.current.add(name)
            changedFields.current.add(name)
            return 1
        }
        return 0
    }

    const defaultSettingsKeys = Object.keys(defaultSettings) as Array<keyof T>

    let counter = 0
    for (let i = 0; i < defaultSettingsKeys.length; i++) {
        const key = defaultSettingsKeys[i]
        counter += undefinedSettingsCount(
            changedFields,
            initializedFields,
            `${name ? name + "." : ""}${key}`,
            defaultSettings[key],
            settings && settings[key]
        )
    }

    return counter
}

const getParentValueName = (name: string) => name.substring(0, name.lastIndexOf("."))

export function useDifferenceCount<T>() {
    const { t } = useTranslation()
    const changedFields = useRef(new Set<string>())
    const initializedFields = useRef(new Set<string>())
    const [differenceCount, setDifferenceCount] = useState(0)

    const { modalOpen, openModal, closeModal, onExited } = useModal(() => (
        <AlertDialog
            show={modalOpen}
            title={t(`${tProjectNamespace}attention`)}
            message={t(`${tProjectNamespace}project-settings-notification`)}
            onSubmit={closeModal}
            variant="primary"
            onExited={onExited}
            onClose={closeModal}
            cancelable={false}
        />
    ))

    const addToChangedFields = useCallback((name: string) => {
        if (!changedFields.current.has(name) && !initializedFields.current.has(name)) {
            changedFields.current.add(name)
            setDifferenceCount(v => v + 1)
        }
    }, [])

    const removeFromChangedFields = useCallback((name: string) => {
        if (changedFields.current.has(name) && !initializedFields.current.has(name)) {
            changedFields.current.delete(name)
            setDifferenceCount(v => v - 1)
        }
    }, [])

    const onOpenSettings = useCallback(
        (defaultSettings: T, settings: T) => {
            const count = undefinedSettingsCount(changedFields, initializedFields, "", defaultSettings, settings)

            if (count) {
                openModal()
                setDifferenceCount(count)
            }
        },
        [openModal]
    )

    const onChange = useCallback(
        debounce((e: FormEvent<HTMLFormElement>, initialValues: T, values: T) => {
            if (
                !(
                    e.target instanceof HTMLInputElement ||
                    e.target instanceof HTMLSelectElement ||
                    e.target instanceof HTMLTextAreaElement
                )
            ) {
                return
            }

            const target = e.target
            let name = target.name
            let isDifferent: boolean

            if (!name) return

            const durationSettingsName = durationSettingsNames.find(n => name?.endsWith(n))
            if (durationSettingsName) {
                name = getParentValueName(name)

                const initialValue = getIn(initialValues, name)
                const currentValue = { ...getIn(values, name), [durationSettingsName]: target.value }

                isDifferent = durationSettingsValuesToMs(initialValue) !== durationSettingsValuesToMs(currentValue)
            } else if (name.endsWith("Timeout.Enabled")) {
                if (!(target instanceof HTMLInputElement)) return

                name = `${getParentValueName(name)}.Message`
                const initialValue = getIn(initialValues, name)
                const currentValue = getIn(values, name)

                isDifferent = target.checked ? initialValue !== currentValue : !!initialValue
            } else {
                const initialValue = getIn(initialValues, name)
                isDifferent =
                    target instanceof HTMLInputElement && target.type === "checkbox"
                        ? initialValue !== target.checked
                        : initialValue !== target.value
            }

            if (isDifferent) {
                addToChangedFields(name)
            } else {
                removeFromChangedFields(name)
            }
        }, wait),
        [addToChangedFields, removeFromChangedFields]
    )

    const onCustomInputChange = useCallback(
        debounce(function <K>(name: string, value: K, initialValues: T) {
            const initialValue = getIn(initialValues, name)
            if (initialValue !== value) {
                addToChangedFields(name)
            } else {
                removeFromChangedFields(name)
            }
        }, wait),
        [addToChangedFields, removeFromChangedFields]
    )

    const reset = useCallback(() => {
        changedFields.current = new Set()
        setDifferenceCount(0)
        closeModal()
    }, [closeModal])

    useEffect(() => {
        return reset
    }, [reset])

    return {
        differenceCount,
        onOpenSettings,
        reset,
        onChange,
        onCustomInputChange
    }
}

export const timeoutSettingsToValues = (timeoutSettings: TimeoutSettings): TimeoutSettingsValues => ({
    Value: msToDurationSettingsValues(timeoutSettings.Value),
    Message: timeoutSettings.Enabled && timeoutSettings.Message ? timeoutSettings.Message : "",
    Enabled: timeoutSettings.Enabled
})

export const msToDurationSettingsValues = (value: number): DurationSettingsValues => {
    const { Days, Hr, Min, Sec } = Time.msToTimeSpan(value)

    return {
        Days,
        Hours: Hr,
        Minutes: Min,
        Seconds: Sec
    }
}

export const durationSettingsValuesToMs = (duration: DurationSettingsValues): number =>
    Time.daysToMs(duration.Days) +
    Time.hoursToMs(duration.Hours) +
    Time.minutesToMs(duration.Minutes) +
    Time.secondsToMs(duration.Seconds)
