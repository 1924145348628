import React from "react"
import styles from "./NotificationItem.module.scss"
import { Notification } from "../../models/notification"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import CloseButton from "../CloseButton/CloseButton"
import { ClassProps } from "../../utility/common/props"
import { formatDistanceToNowLocal } from "../../utility/common/time"
import Spinner from "../Spinner/Spinner"
import { NotificationContentFunction } from "../../utility/notifications/notificationContentType"
import NotificationInviteOperatorItem from "./NotificationInviteOperatorItem"
import NotificationDefaultItem from "./NotificationDefaultItem"
import NotificationImportFilesItem from "./NotificationImportFilesItem/NotificationImportFilesItem"
import NotificationExportFilesItem from "./NotificationExportFilesItem/NotificationExportFilesItem"
import type { AcceptInviteOperatorFunction } from "../../models/operator"
import { testId } from "../../utility/tests/testId"

export interface NotificationItemProps extends ClassProps {
    notification: Notification
    onClose?: (notificationId: string) => void
    onClick?: (link: string) => void
    closing?: boolean
    disableDate?: boolean
    onAcceptInviteOperator?: AcceptInviteOperatorFunction
}

const NotificationItem: React.FC<NotificationItemProps> = props => {
    const {
        notification,
        onClose,
        onClick,
        closing = false,
        disableDate = false,
        className,
        onAcceptInviteOperator
    } = props
    const { t } = useTranslation()
    const { iconContent, mainContent, buttons } = getNotificationContent(notification, t, onAcceptInviteOperator)

    const handleClose = () => onClose?.(notification.Id)

    return (
        <div
            className={cn(
                styles.notificationItemContainer,
                styles[`notificationItemContainer_${notification.Type}`],
                notification.Link && styles.notificationItemContainer_clickable,
                className
            )}
        >
            <div
                className={styles.notificationItemContainer__payload}
                data-testid={testId.notificationPayload}
                onClick={() => notification.Link && onClick?.(notification.Link)}
            >
                <div className={styles.notificationItemContainer__icon}>{iconContent}</div>
                <div className={styles.notificationItemContainer__content}>
                    {mainContent}
                    {!disableDate && (
                        <div className={styles.notificationItemContainer__date}>
                            {formatDistanceToNowLocal(notification.Timestamp)}
                        </div>
                    )}
                    <div className={styles.notificationItemContainer__buttonsContainer}>{buttons}</div>
                </div>
            </div>
            {onClose && (
                <div className={styles.notificationItemContainer__close} onClick={handleClose}>
                    {closing ? (
                        <div className={styles.notificationItemContainer__spinnerContainer}>
                            <Spinner />
                        </div>
                    ) : (
                        <CloseButton />
                    )}
                </div>
            )}
        </div>
    )
}

const getNotificationContent: NotificationContentFunction = (notification, ...args) => {
    switch (notification.Type) {
        case "inviteOperator":
            return NotificationInviteOperatorItem(notification, ...args)
        case "importFiles":
            return NotificationImportFilesItem(notification, ...args)
        case "exportFiles":
            return NotificationExportFilesItem(notification, ...args)
        default:
            return NotificationDefaultItem(notification, ...args)
    }
}

export default NotificationItem
