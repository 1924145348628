import React, { useCallback, useEffect } from "react"
import { GlobalUser } from "../../models/globalUser"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import SettingsTableLoader from "../SettingsTable/SettingsTableLoader"
import { GlobalUserSurvey } from "../../models/globalUserSurvey"
import { useLazyGetAllUsersByPageQuery } from "../../api/controllers/users"
import { ITEMS_PER_PAGE, PaginationRequest } from "../../models/pagination"
import AsyncQueries, { TGetQueryResult } from "../Async/AsyncQueries"
import { useLazyGetAllGlobalPermissionsQuery } from "../../api/controllers/roles"
import { useLazyGetGlobalUserSurveyQuery } from "../../api/controllers/surveys"
import GlobalUsersTable from "./GlobalUsersTable/GlobalUsersTable"

type GlobalUsersContainerProps = {
    onSettingsClick: (user: GlobalUser, allGlobalPermissions: string[], globalUserSurvey: GlobalUserSurvey) => void
}

const GlobalUsersContainer = (props: GlobalUsersContainerProps) => {
    const { onSettingsClick } = props

    const [getAllUsersTrigger, getAllUsersQuery] = useLazyGetAllUsersByPageQuery()
    const [getAllGlobalPermissionsTrigger, getAllGlobalPermissionsQuery] = useLazyGetAllGlobalPermissionsQuery()
    const [getGlobalUserSurveyTrigger, getGlobalUserSurveyQuery] = useLazyGetGlobalUserSurveyQuery()

    const loadData = useCallback(async () => {
        const allUsersRequest: PaginationRequest = {
            PageFrom: 0,
            PageSize: ITEMS_PER_PAGE,
            Search: ""
        }

        getAllUsersTrigger(allUsersRequest)
        getAllGlobalPermissionsTrigger()
        getGlobalUserSurveyTrigger()
    }, [])

    useEffect(() => {
        loadData()
    }, [loadData])

    return (
        <>
            <AsyncQueries
                queries={[getAllUsersQuery, getAllGlobalPermissionsQuery, getGlobalUserSurveyQuery]}
                defaultView={<SettingsTableLoader columns={3} rows={2} rowHeight={65} />}
                processView={<SettingsTableLoader columns={3} rows={2} rowHeight={65} />}
                errorView={({ message }) => <ErrorMessage text={message} />}
            >
                {({ data }) => {
                    const allUsers = data[0] as TGetQueryResult<typeof getAllUsersQuery>
                    const allGlobalPermissions = data[1] as TGetQueryResult<typeof getAllGlobalPermissionsQuery>
                    const globalUserSurvey = data[2] as TGetQueryResult<typeof getGlobalUserSurveyQuery>

                    return (
                        <>
                            <GlobalUsersTable
                                users={allUsers}
                                onSettingsClick={v => onSettingsClick(v, allGlobalPermissions, globalUserSurvey.Survey)}
                                globalUserSurvey={globalUserSurvey.Survey}
                                allUsersQueryTriggerCallback={getAllUsersTrigger}
                            />
                        </>
                    )
                }}
            </AsyncQueries>
        </>
    )
}

export default GlobalUsersContainer
