import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { SurveyDto } from "../../models/survey"
import { resetReducerState } from "../action"

export type SurveysState = Readonly<{
    surveys: AsyncState<SurveyDto[]>
    createSurvey: AsyncState<void>
    updateSurvey: AsyncState<void>
    deleteSurvey: AsyncState<void>
}>

const initialState: SurveysState = {
    surveys: AsyncState.create(),
    createSurvey: AsyncState.create(),
    updateSurvey: AsyncState.create(),
    deleteSurvey: AsyncState.create()
}

const surveys = createSlice({
    name: "surveys",
    initialState,
    reducers: {
        getSurveysProcess(state) {
            state.surveys = state.surveys.toProcess()
        },
        getSurveysSuccess(state, action: PayloadAction<SurveyDto[]>) {
            state.surveys = state.surveys.toSuccess(action.payload)
        },
        getSurveysFailed(state, action: PayloadAction<SystemError>) {
            state.surveys = state.surveys.toFailed(action.payload)
        },
        createSurveyProcess(state) {
            state.createSurvey = state.createSurvey.toProcess()
        },
        createSurveySuccess(state, action: PayloadAction<SurveyDto>) {
            state.createSurvey = state.createSurvey.toSuccess()
            state.surveys = state.surveys.map(v => [...v, action.payload])
        },
        createSurveyFailed(state, action: PayloadAction<SystemError>) {
            state.createSurvey = state.createSurvey.toFailed(action.payload)
        },
        updateSurveyProcess(state) {
            state.updateSurvey = state.updateSurvey.toProcess()
        },
        updateSurveySuccess(state, action: PayloadAction<SurveyDto>) {
            state.updateSurvey = state.updateSurvey.toSuccess()
            state.surveys = state.surveys.map(v =>
                v.map(survey => (survey.Id === action.payload.Id ? action.payload : survey))
            )
        },
        updateSurveyFailed(state, action: PayloadAction<SystemError>) {
            state.updateSurvey = state.updateSurvey.toFailed(action.payload)
        },
        deleteSurveyProcess(state) {
            state.deleteSurvey = state.deleteSurvey.toProcess()
        },
        deleteSurveySuccess(state, action: PayloadAction<string>) {
            state.deleteSurvey = state.deleteSurvey.toSuccess()
            state.surveys = state.surveys.map(v => v.filter(survey => survey.Id !== action.payload))
        },
        deleteSurveyFailed(state, action: PayloadAction<SystemError>) {
            state.deleteSurvey = state.deleteSurvey.toFailed(action.payload)
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export const SurveysReducer = surveys.reducer

export const actions = surveys.actions
