import React, { HTMLAttributes } from "react"
import { isTask, isTaskOfAutomaticProcessing, SwitchTaskRequest, TaskCommon } from "../../models/task"
import { EDialogTransferType, TDialogTransferModalFormValues } from "../DialogTransferModal/DialogTransferModalForm"
import {
    useOperatorDialogTransferToOperatorMutation,
    useOperatorDialogTransferToQueueMutation
} from "../../api/controllers/dialogs"
import styles from "./RouteAssignedTaskForm.module.scss"
import cn from "classnames"
import { useDispatch } from "react-redux"
import { switchTask } from "../../store/tasks/thunks"
import DialogTransferFormSidebar from "./DialogTransferFormSidebar"
import { getMainQueuePart } from "../../store/queues/slice"

interface Props extends HTMLAttributes<HTMLDivElement> {
    task: TaskCommon
    onAfterRoute: () => void
}

const RouteTaskForm: React.FC<Props> = props => {
    const { task, onAfterRoute, ...restProps } = props
    const dispatch = useDispatch()

    const [runOperatorDialogTransferToQueue] = useOperatorDialogTransferToQueueMutation()
    const [runOperatorDialogTransferToOperator] = useOperatorDialogTransferToOperatorMutation()

    const defineQueueId = () => ("QueueId" in task && !!task.QueueId ? task.QueueId : "")
    const queueId = getMainQueuePart(defineQueueId())

    const handleRerouteFromAutoProcessing = (formData: TDialogTransferModalFormValues) => {
        const { selectedQueueId, queueIdOnOperatorRefused, selectedOperatorId } = formData
        const request: SwitchTaskRequest = {
            OperatorId: "",
            QueueId: "",
            TaskId: task.Id
        }

        if (formData.transferType === EDialogTransferType.Queue) {
            request.QueueId = selectedQueueId
        }

        if (formData.transferType === EDialogTransferType.Operator) {
            request.OperatorId = selectedOperatorId
            request.QueueId = queueIdOnOperatorRefused
        }

        dispatch(switchTask(request, onAfterRoute))
    }

    const handleOperatorDialogTransfer = async (formData: TDialogTransferModalFormValues) => {
        if (formData.transferType === EDialogTransferType.Queue) {
            const { transferMessage, selectedQueueId } = formData

            await runOperatorDialogTransferToQueue({
                Message: transferMessage,
                QueueId: selectedQueueId,
                DialogId: task.Id,
                IsSupervisor: true
            })
        }

        if (formData.transferType === EDialogTransferType.Operator) {
            const { transferMessage, queueIdOnOperatorRefused, selectedOperatorId } = formData

            await runOperatorDialogTransferToOperator({
                Message: transferMessage,
                QueueId: queueIdOnOperatorRefused,
                OperatorId: selectedOperatorId,
                DialogId: task.Id,
                IsSupervisor: true
            })
        }

        onAfterRoute()
    }

    return (
        <DialogTransferFormSidebar
            channelId={(isTask(task) ? task.Channel.Id : task.ChannelId) ?? ""}
            onSubmitForm={
                isTaskOfAutomaticProcessing(task) ? handleRerouteFromAutoProcessing : handleOperatorDialogTransfer
            }
            includeCurrentUser
            className={cn(restProps.className, styles.routeTaskForm)}
            queueId={isTaskOfAutomaticProcessing(task) ? "bot" : queueId}
        />
    )
}

export default RouteTaskForm
